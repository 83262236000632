<!-- eslint-disable max-lines -->
<template>
    <div class="contract-manage">
        <Paper class="contract-manage-paper">
            <el-tabs
                class="contract-list-tab-pane"
                v-model="activeTabs"
                @tab-remove="removeTab"
                @tab-click="switchTabs">
                <!-- 合同列表页 -->
                <el-tab-pane
                    label="合同列表"
                    name="theList"
                    ref="myPane"
                    v-loading="holePageLoading">
                    <list-layout-paper>
                        <template slot="header-search" class="searchBox">
                            <search-box
                                ref="searchBox"
                                :search-key="config.name"
                                :fields="config.searchFields"
                                :showResetBtn="true"
                                @search-change="contractList.searchList"
                                @elementChange="elementChange"
                            />
                        </template>
                        <template slot="header-button">
                            <lotsButton
                                v-has="authorityCode.addNewMain"
                                type="primary"
                                size="mini"
                                @click="addNew"
                                title="新增">
                                <span class="icon iconfont">&#xe66a;</span> 新增
                            </lotsButton>
                            <lotsButton
                                v-has="authorityCode.exportMain"
                                type="primary"
                                size="mini"
                                title="导出">
                                <el-dropdown class="contract-manage-list-dropdown" trigger="click" :append-to-body="false">
                                    <span class="el-dropdown-link">
                                        导出<i class="el-icon-arrow-down el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" class="contract-manage-list-dropdown-menu">
                                        <el-dropdown-item class="dropdown-item" title="导出">
                                            <report-export
                                                v-has="authorityCode.exportMain"
                                                #default="{ submit }"
                                                :reportCode="reportCodeNormal">
                                                <div class="dropdown-item-span" @click="handleExport(submit, 'normal')">导出</div>
                                            </report-export>
                                        </el-dropdown-item>
                                        <el-dropdown-item class="dropdown-item" title="按分公司拆分导出">
                                            <report-export
                                                v-has="authorityCode.exportMain"
                                                #default="{ submit }"
                                                :reportCode="reportCodeSplit">
                                                <div class="dropdown-item-span" @click="handleExport(submit, 'split')">拆分导出</div>
                                            </report-export>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </lotsButton>
                            <lotsButton
                                type="primary"
                                size="mini"
                                title="刷新"
                                @click="refresh"
                                plain>
                                <span class="icon iconfont icon-refresh"></span>
                            </lotsButton>
                            <el-tooltip effect="dark" :content="'列配置'" placement="bottom">
                                <el-button
                                icon="iconfont icon-list_install"
                                type="primary"
                                size="mini"
                                :title="'列配置'"
                                @click="showColumnConfigDialog"
                                plain />
                            </el-tooltip>
                        </template>
                        <!-- 表格 -->
                        <template v-slot:list="{ tableHeight }">
                            <table-box
                                ref="clientOrder"
                                :height="tableHeight"
                                v-loading="contractList.tableLoading"
                                :selection="config.selection"
                                :index="config.index"
                                :columns="config.tableData.columns"
                                :rows="contractList.totalData"
                                :actions="config.actions"
                                @action-click="actionClick"
                                @row-dblclick="rowDBclick"
                                @selection-change="handleSelectionChange">
                                <template v-slot:col-append="{ col, row }">
                                    <div v-if="col.prop === 'businessType'">{{col.prop === 'businessType' ? row.busiLabelList : row.businessType}}</div>
                                </template>
                            </table-box>
                        </template>
                        <template slot="footer">
                            <lots-pagination
                                @size-change="contractList.sizeChange"
                                :current-page.sync="contractList.pageNo"
                                @current-change="contractList.pageChange"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="contractList.pageTotal" >
                            </lots-pagination>
                        </template>
                    </list-layout-paper>
                </el-tab-pane>
                <!-- 详情页签 -->
                <el-tab-pane
                    class="detail-pane"
                    closable
                    v-for="item in tabList"
                    :key="item.code + item.detailPageState"
                    :label="item.contractMark"
                    :name="item.code + item.detailPageState">
                    <price-main
                        v-if="item.detailPageState === 'price'"
                        :detailData="item.datailData"
                        :activeTabs="activeTabs">
                    </price-main>
                    <detail-tab
                        v-else
                        :ref="`datailTabs${item.code + item.detailPageState}`"
                        :code="item.code"
                        :detailPageState="item.detailPageState"
                        :disableBus="item.buttonData"
                        :detailData="item.datailData"
                        @save-data="detailSaveData"
                        @add-new="addNew"
                        @other-change="otherChange"
                        @stop-change="contractStop"
                        @refrash-data="refrashData"
                        @replenish-contract="replenishContract"
                        @price-click="priceClick"
                        @close-page="removeTab"
                        @subTabDbClick="rowDBclick">
                    </detail-tab>
                </el-tab-pane>
            </el-tabs>
            <!-- 表格列配置弹框 -->
            <column-config
                :pageID="config.name"
                :initColumns="config.tableData.columns"
                ref="otpColumnConfig"
                @header-change="config.tableData.columns = $event"
            />
            <!-- 新增合同弹窗 -->
            <add-new-main
                v-if="addNewMainvisible"
                :addNewMainLoading="addNewMainLoading"
                @handleClose="updateAddNewMainVisible"
                @addNewConfirm="addNewConfirm">
            </add-new-main>
        </Paper>
    </div>
</template>
<script>
import Paper from '@/components/core/Paper';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import listLayoutPaper from '@/components/lots/listLayout/Paper';
import searchBox from '@/components/lots/searchBox';
import tableBox from '@/components/lots/tableBox/Index';
import detailTab from './detail/Detail.vue';
import { getDictDataApi, getContractInfoAuthList, contractDelete, getByContractCodeApi, getUserDataAuthsApi } from '@mdm/api/contractManage/contractListApi.js';
import { searchContractByOtherApi, contractChangeDetailGetByCodeApi } from '@mdm/api/contractManage/detailMainApi.js';
import { AdvanceListClass } from '@/modules/mdm/composition/dc/common/list/AdvanceListClass';
import { config as configStatic } from './config';
import { ref, reactive, onMounted, onUnmounted } from '@vue/composition-api';
import { Message } from 'element-ui';
import utils from '@/components/utils/common.js';
import lotsButton from '@/components/lots/lotsButton';
import columnConfig from '@/components/lots/columnConfig/Index';
import {
    STATE_MAIN,
    STATE_OTHER_CHANGE,
    STATE_NEW,
    STATE_REPLENISH,
    STATE_STOP,
    STATE_PRICE,
    CONTRACT_CHARACTER
} from '@/modules/mdm/constant/contractManage.js';
import store from '@/store';
import _ from 'lodash';
import reportExport from '@/components/lots/reportExportPlugin';
import Bus from '@/utils/bus.js';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import addNewMain from './addNewDialog/Index.vue';
import bigDataLog from '@/utils/bigDataLog.js';
const { MAGIC_NUMBER } = utils;
export default {
    name: 'tianfuBankContract',
    components: {
        Paper,
        listLayoutPaper,
        searchBox,
        tableBox,
        lotsPagination,
        lotsButton,
        columnConfig,
        detailTab,
        reportExport,
        addNewMain
    },
    /* eslint-disable max-lines-per-function */
    setup (props, setupContext) {
        const authorityCode = reactive(btnAuthority.contractManage);
        Bus.$on('viewContractDetail', ({ contractCode, isOtherChange }) => { // 从mip审批流程页跳转
            if (isOtherChange) {
                contractChangeDetailGetByCodeApi({ contractChangeCode: contractCode }).then(res => {
                    if (+res.code === 0) {
                        rowDBclick(res.data, 'otherChange');
                    }
                });
                return;
            }
            getByContractCodeApi({ contractCode }).then(res => {
                if (+res.code === 0) {
                    rowDBclick(res.data);
                }
            });
        });
        const openOtherChange = async () => {
            const { contractCode, contractChangeCode, todoType, fdFlowId } = store.getters.contractManageParams;
            if (!fdFlowId) return;
            // 先打开主合同
            await getByContractCodeApi({ contractCode }).then(res => {
                if (+res.code === 0) {
                    rowDBclick(res.data);
                }
            });
            // 然后打开变更单
            contractChangeDetailGetByCodeApi({ contractChangeCode }).then(res => {
                if (+res.code === 0) {
                    rowDBclick({
                        ...res.data,
                        todoType: todoType,
                        fdFlowId: fdFlowId
                    }, 'otherChange');
                }
            });
            store.commit('getContractManageParams', {});
        };
        // 组件卸载时，取消订阅
        onUnmounted(() => {
            Bus.$off('viewContractDetail');
        });
        const { user } = store.getters;
        const tenantCode = store.getters.currentTenant.tenantCode;
        const busiTypeList = ref({});
        onMounted(async () => {
            const queryParams = {
                createTime: utils.defaultDate('15') // 默认选中前15天(当前日期23.59.59 到15天前的0.0.0)时间范围
            };
            setupContext.refs.searchBox.setQueryParams(queryParams);
            setupContext.refs.searchBox.submitSearch();
            const dictData = await getDictDataApi({ dictCodes: 'SYS_BMS_BUSI_TYPE,SYS_CONTRACT_PRODUCT_TYPE' });
            busiTypeList.value = dictData.data['SYS_BMS_BUSI_TYPE'];
            openOtherChange();
        });
        class ContractListClass extends AdvanceListClass {
            beforeGetList(condition) {
                bigDataLog.sendDetailMessageByKey('bigData-btn-listPageSearch'); // 搜索埋点
                return searchDataFormat(condition);
            };
            afterGetList (res) {
                if (+res.code === 0) {
                    this.totalData.value = res.data.list.map(v => {
                        const busiList = v.businessType ? v.businessType.split(',') : [];
                        const busiLabelList = busiList.map(v2 => busiTypeList.value[v2]);
                        v.busiLabelList = busiLabelList.join();
                        v.validTime = utils.formatDate(v.validTime);
                        v.failTime = utils.formatDate(v.failTime);
                        return v;
                    });
                    this.pageTotal.value = res.data.totalCount;
                }
            };
        }
        const contractList = new ContractListClass({
            Api: {
                listApi: getContractInfoAuthList, // 查询接口
                deleteApi: contractDelete // 删除行接口
            }
        });
        const searchDataFormat = (condition) => {
            if (condition.createTime && condition.createTime.length) {
                condition.startTime = utils.formatDateTime(condition.createTime[0]);
                condition.endTime = utils.formatDateTime(condition.createTime[1]);
                Reflect.deleteProperty(condition, 'createTime');
            }
            if (condition.validTime && condition.validTime.length) {
                condition.startValidTime = utils.formatDateTime(condition.validTime[0]);
                condition.endValidTime = utils.formatDateTime(condition.validTime[1]);
                Reflect.deleteProperty(condition, 'validTime');
            }
            if (condition.failTime && condition.failTime.length) {
                condition.startFailTime = utils.formatDateTime(condition.failTime[0]);
                condition.endFailTime = utils.formatDateTime(condition.failTime[1]);
                Reflect.deleteProperty(condition, 'failTime');
            }
            if (condition.selectList && condition.selectList.length) {
                condition.createUserCode = condition.selectList[0].userCode;
            }
            Reflect.deleteProperty(condition, 'selectList');
            Reflect.deleteProperty(condition, 'userName');
            condition.userCode = user.userCode;
            condition.isNew = condition.isNew ? +condition.isNew : null;
            Object.keys(condition).forEach(v => {
                !condition[v] && condition[v] !== 0 && (condition[v] = undefined);
            });
            return condition;
        };
        const config = reactive(configStatic);
        const holePageLoading = ref(false);
        const selectedList = ref([]);
        const refresh = () => {
            contractList.getList();
        };
        const elementChange = (data) => {
            // console.log('搜索改变data', data);
        };
        const handleSelectionChange = (data) => {
            selectedList.value = data;
        };
        const otpColumnConfig = ref(null);
        const showColumnConfigDialog = () => {
            otpColumnConfig.value.show(true);
        };
        const activeTabs = ref('theList');
        const removeTab = (targetName) => { // 删除详情标签
            if (activeTabs.value === targetName) {
                activeTabs.value = 'theList';
            }
            tabList.value = tabList.value.filter(
                (tab) => (tab.code + tab.detailPageState) !== targetName
            );
        };
        const switchTabs = (data) => {
            const comKey = `datailTabs${data.name}`;
            if (setupContext.refs[comKey] && setupContext.refs[comKey].length) {
                const { activeName } = setupContext.refs[comKey][0];
                setupContext.refs[comKey][0].handleClickTab({ name: activeName }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const tabList = ref([]); // 打开的详情tab页集
        const currDetailData = ref({});
        const formatData = (data) => { // 特殊数据格式处理函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            return data;
        };
        // 双击表格
        const rowDBclick = (row, pageType = '') => {
            // 滚动到顶部
            const element = document.getElementsByClassName('app-main');
            element[0].scrollTo(0, 0);
            currDetailData.value = formatData(_.cloneDeep(row)); // 深拷贝是防止表格源数据被修改
            let status = '';
            let name = '';
            /* contractCharacter: 合同性质：1 新签，2 续签，3 补充协议，4 预签， 5 终止协议 */
            /* status: 合同tab页状态: 'stop','replenish','otherChange','main' */
            if (pageType === 'otherChange') {
                // 其他变更合同
                status = STATE_OTHER_CHANGE; // 'otherChange' 其他变更
                name = '其他变更';
            } else if (String(row.contractCharacter) === CONTRACT_CHARACTER.TERMINATION || pageType === 'stop') { // 5终止协议
                status = STATE_STOP; // 'stop' 终止
                name = '终止协议';
            } else if (String(row.contractCharacter) === CONTRACT_CHARACTER.REPLENISH || pageType === 'replenish') { // 3补充协议  这里按钮都和主合同的一样，所以就改下标题和状态就ok
                status = STATE_REPLENISH; // 'replenish'
                name = '补充协议';
            } else {
                status = STATE_MAIN; // 'main' 主合同
                name = '主合同';
            }
            let code = '';
            if (status === STATE_OTHER_CHANGE) { // 新增其他变更未保存时，使用合同编码作为识别码；变更保存后，以变更编码作为识别码
                code = currDetailData.value.contractChangeCode ? currDetailData.value.contractChangeCode : currDetailData.value.contractCode + currDetailData.value.changeType;
            } else {
                code = currDetailData.value.contractCode;
            }
            newDetailPage(code, currDetailData.value, name, status);
        };

        /**
     * @description: 开详情页通用部分代码
     * @param {String} code 页面编码
     * @param {Object} data 页面数据对象
     * @param {String} name 页面名称信息
     * @param {String} state 页面状态  合同tab页状态
     *  */
        // eslint-disable-next-line complexity
        const newDetailPage = (code, data, name, state) => {
            data.contractStatus = data['contractStatus'] ? data['contractStatus'] : 0;
            data.contractSource = data.contractSource ? data.contractSource : '1'; // 新增时默认为1：CRM
            data.oilLinkedProportion = data.oilLinkedProportion || ((['4', '5'].includes(String(data.contractCharacter)) || data.contractSource !== '1') ? '0' : '');
            const judge = tabList.value.filter((item) => (item.code + item.detailPageState) === code + state);
            // data.contractAccountOf = data.contractAccountOf || 0;
            if (judge.length === 0 && tabList.value.length === config.PANELMAX) {
                Message.warning('已超出8个详情页，请关闭后重新打开');
            } else {
                const dataGather = {
                    contractMark: name + code.substring(code.length - config.LASTFOUR),
                    code: code,
                    datailData: _.cloneDeep(data),
                    detailPageState: state
                };
                if (code === '0000') {
                    dataGather.contractMark = name;
                }
                if (state === STATE_PRICE) {
                    dataGather.contractMark = '主合同' + data.contractCode.substring(data.contractCode.length - config.LASTFOUR) + '价格表';
                }
                if (state === STATE_STOP || state === STATE_REPLENISH) {
                    dataGather.contractMark = name + code.substring(code.length - config.LASTSIX);
                }
                if (state === STATE_OTHER_CHANGE && data.contractChangeCode) {
                    dataGather.contractMark = name + code.substring(code.length - 7);
                }
                if (judge.length === 0) {
                    // 第一次打开
                    tabList.value.push(dataGather);
                } else { // 已经打开的，直接找到该页面
                    const index = tabList.value.findIndex(v => (v.code + v.detailPageState === judge[0].code + judge[0].detailPageState));
                    if (state !== STATE_NEW) { // 非新增页面，刷新页面数据 (新增页面，不覆盖已填写的数据， )
                        tabList.value[index] = dataGather;
                    }
                }
                activeTabs.value = code + state; // tabs切换到对应详情页
                switchTabs({ name: activeTabs.value }); // 解决切换详情页tab子页签编辑表格错位问题
            }
        };
        const actionClick = (event, row) => {
            if (event === 'delItem') {
                contractList.deleteRow(row);
            }
        };
        const detailSaveData = (contractData, detailPageState) => {
            if (detailPageState === STATE_NEW) { // 详情页面新增合同保存触发，保存成功后重写页面信息(新增合同或补充协议)
                const handleTabItem = tabList.value.find(item => {
                    return item.detailPageState === STATE_NEW;
                });
                handleTabItem.code = contractData.contractCode;
                const STATE = contractData.contractCharacter !== 3 ? STATE_MAIN : STATE_REPLENISH;
                handleTabItem.detailPageState = STATE;
                rowDBclick(contractData, STATE);
            } else if (detailPageState === STATE_OTHER_CHANGE) { // 新增的其他变更保存触发
                const handleTabItem = tabList.value.find(item => {
                    return item.detailPageState === STATE_OTHER_CHANGE && item.code === contractData.contractCode + contractData.changeType;
                });
                handleTabItem.code = contractData.contractChangeCode; // 使重开的页面可以匹配原来的其他变更（未保存）页面重写页面信息
                rowDBclick(contractData, STATE_OTHER_CHANGE);
            }
        };
        const addNewMainvisible = ref(false);
        const updateAddNewMainVisible = (val) => {
            addNewMainvisible.value = val;
        };
        const addNewMainLoading = ref(false);
        const addNewConfirm = async(data = {}) => {
            let newPageData = {};
            if (['2', '3'].includes(data.contractSource)) { // 调接口预填充(LSRM，PMS)
                addNewMainLoading.value = true;
                const res = await searchContractByOtherApi({
                    projectCode: data.projectCode,
                    projectType: data.projectType
                });
                addNewMainLoading.value = false;
                if (+res.code === 0) {
                    newPageData = Object.assign({}, res.data, data);
                    newPageData.contractAccountOf = +newPageData.contractSource === 1 ? '' : (newPageData.contractAccountOf || 0);
                    updateAddNewMainVisible(false); // 关闭新增弹框
                    newDetailPage('0000', newPageData, '新增合同', STATE_NEW);
                }
            } else { // 直接打开
                newPageData = data;
                newPageData.productType = '2'; // crm 默认普通合同
                updateAddNewMainVisible(false); // 关闭新增弹框
                newDetailPage('0000', newPageData, '新增合同', STATE_NEW);
            }
        };
        const addNew = (data) => { // 新增合同信息
            let passFlag = true;
            tabList.value.forEach(item => {
                if (item.detailPageState === STATE_NEW) {
                    passFlag = false;
                    Message.warning('存在新增未保存的草稿合同，请先保存或关闭草稿后再新建新合同');
                    setTimeout(() => {
                        activeTabs.value = item.code + item.detailPageState;
                    }, MAGIC_NUMBER.ONE_THOUSAND);
                }
            });
            if (passFlag) {
                if (data && data.buChong) { // 新增补充协议
                    newDetailPage('0000', data, '新增补充协议', STATE_NEW);
                } else {
                    updateAddNewMainVisible(true);
                }
            }
            return passFlag;
        };
        const refrashData = (data) => {
            rowDBclick(data);
        };
        const otherChange = (contractCode, newPageData, pageType) => { // 新增其他变更界面
            rowDBclick(newPageData, pageType);
        };
        const priceClick = (contractCode, newPageData) => { // 新增价格维护
            newDetailPage(newPageData.contractCode, newPageData, '', STATE_PRICE);
        };
        const replenishContract = (contractCode, newPageData, pageType) => { // 新增补充协议界面
            newPageData.contractCharacter = CONTRACT_CHARACTER.REPLENISH;
            rowDBclick(newPageData, pageType);
        };
        const contractStop = (contractCode, newPageData, pageType) => { // 新增终止协议界面
            newPageData.contractCharacter = CONTRACT_CHARACTER.TERMINATION;
            rowDBclick(newPageData, pageType);
        };
        const reportCodeNormal = ref('REPORT_CRM_NON_ROLE');
        const reportCodeSplit = ref('REPORT_CRM_NON_ROLE_SPLIT');
        const handleExport = async(submit, type) => {
            if (type === 'split' && !setupContext.refs.searchBox.searchData.oilIsGroupCustomer) {
                Message.warning('请选择内外部业务再导出');
                return false;
            }
            await setupContext.refs.searchBox.submitSearch(); // 解决输入参数不生效问题
            const { value } = contractList.searchModel;
            const newData = _.cloneDeep(value); // 解决搜索参数被清空的问题
            const requestResults = [];
            config.tableData.columns.forEach((item) => {
                if (!item.hiddenColumn) {
                    if (item.label === '主要开发人') {
                        requestResults.push(item.label, '主要开发人美信号');
                    } else {
                        requestResults.push(item.label);
                    }
                }
            });
            const params = {
                ...searchDataFormat(newData),
                tenantCode: tenantCode,
                requestResults: requestResults.join(',')
            };
            await getUserDataAuthsApi({ userCode: user.userCode }).then(res => {
                if (+res.code === 0 && res.data) {
                    params.roleCode = res.data.roleCode;
                    params.authChannelCodeList = res.data.authChannelCodeList || null;
                    params.authSiteCodeList = res.data.authSiteCodeList || null;
                    params.authIndustryCodeList = res.data.authIndustryCodeList || null;
                    if (params.roleCode && +params.roleCode === 1) { // 行业角色
                        reportCodeNormal.value = 'REPORT_CRM_INDUSTRY_ROLE';
                        reportCodeSplit.value = 'REPORT_CRM_INDUSTRY_ROLE_SPLIT';
                    } else if (params.roleCode && +params.roleCode === 2) { // 非行业角色
                        reportCodeNormal.value = 'REPORT_CRM_NON_INDUSTRY_ROLE';
                        reportCodeSplit.value = 'REPORT_CRM_NON_INDUSTRY_ROLE_SPL';
                    } else { // 普通角色
                        reportCodeNormal.value = 'REPORT_CRM_NON_ROLE';
                        reportCodeSplit.value = 'REPORT_CRM_NON_ROLE_SPLIT';
                    }
                }
            });
            Object.keys(params).forEach(v => {
                if (params[v] === undefined || params[v] === null || params[v] === '') {
                    delete params[v];
                }
            });
            submit(params);
        };
        return {
            contractList,
            config,
            selectedList,
            refresh,
            handleSelectionChange,
            otpColumnConfig,
            showColumnConfigDialog,
            activeTabs,
            removeTab,
            switchTabs,
            tabList,
            rowDBclick,
            currDetailData,
            actionClick,
            detailSaveData,
            addNew,
            refrashData,
            otherChange,
            replenishContract,
            contractStop,
            priceClick,
            holePageLoading,
            tenantCode,
            utils,
            handleExport,
            authorityCode,
            addNewMainvisible,
            updateAddNewMainVisible,
            addNewConfirm,
            addNewMainLoading,
            elementChange,
            reportCodeNormal,
            reportCodeSplit
        };
    }
};
</script>
<style lang="less">
.contract-manage {
    width: 100%;
    .contract-manage-paper {
        height: 100%;
    }
    .list-layout-wrapper {
        height: 100%;
    }
    .contract-list-tab-pane.el-tabs {
        width: 100%;
        height: 100%;
        & > .el-tabs__content {
            height: calc(100% - 40px - 14px);
            overflow: auto;
            & > .el-tab-pane {
                overflow: auto;
                padding-right: 5px;
                height: 100%;
                .list-main {
                    .table-box {
                        height: 100%;
                    }
                }
            }
        }
    }
    .flex-layout .list-main {
        overflow: auto;
    }
    .list-layout .list-header .el-input{
        margin-left: 0;
    }
    .flex-layout .table {
        overflow-y: auto;
    }
    .list-header {
        .contract-manage-list-dropdown.el-dropdown {
            color: #fff;
        }
    }

    .layout-content.contract-manage-paper {
        padding: 0px 20px 20px;
        .el-table .cell.el-tooltip {
            &>div {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .list-layout-wrapper {
        padding: 0px;
    }
}
.contract-manage-list-dropdown-menu {
    background-color: #fff;
}
.column-config_title {
    font-size: 14px;
}
</style>
