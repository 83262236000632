<template>
    <div class="print-archive">
        <el-dialog
            :visible="true"
            width="400px"
            title="归档条码："
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <div class="print-father">
                <div class="label-box" id="printView">
                    <!-- <img id="barcode" /> -->
                    <svg id="barcode"></svg>
                    <div class="info-box">
                        <div class="info">单据编码：{{ formData.contractCode }}</div>
                        <div class="info">合同编码：{{ formData.mipContractCode }}</div>
                    </div>
                </div>
            </div>
            <span slot="footer">
                <el-button size="mini" @click="emit('handleClose', false)">取消</el-button>
                <lots-button type="primary" size="mini" v-print="'#printView'">确认打印</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, onMounted } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import _ from 'lodash';
import JsBarcode from 'jsbarcode';
export default {
    components: {
        lotsButton
    },
    props: {
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const { emit } = setupContext;
        const formData = reactive(_.cloneDeep(props.detailData));
        onMounted(() => {
            renderBarcode();
        });
        const renderBarcode = () => {
            setupContext.root.$nextTick(() => {
                JsBarcode('#barcode', formData.archiveCode, {
                    format: 'CODE128', // 条码类型
                    lineColor: '#000', // 线条颜色
                    width: 1.3, // 间隔宽度
                    height: 45, // 图片高度
                    margin: 5,
                    fontSize: 14, // 字体大小
                    font: 'monospace,simsun',
                    displayValue: true // 是否显示条码下方的数字
                });
            });
        };
        const confirm = () => {
            // 创建 canvas 元素
        };
        return {
            formData,
            emit,
            confirm,
            renderBarcode
        };
    }
};
</script>
<style lang="less">
.print-archive {
    .footer {
        text-align: center;
        margin: 10px 0;
        .el-button {
            padding: 0 20px;
        }
    }
    .el-select, .el-input-number {
        width: 80%;
    }
    .el-dialog__body {
        padding: 15px;
    }
    #printView {
        padding: 5px;
    }
    .print-father {
        width: 100%;
        height: 100%;
    }
    .label-box {
        border: #a5a4a4 solid 1px;
        padding: 10px;
        display: inline-block;
        // text-align: center;
        .info-box {
            margin-top: 4px;
            .info {
                font-size: 12px;
                color: #000;
                padding: 2px 0;
                margin: 0;
            }
        }
    }
}
</style>
