<template>
    <div class="add-changeProtocol-dialog">
        <el-dialog
            :visible="true"
            title="新增补充协议"
            width="1000px"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            v-loading="addChangeProtocolLoading"
            @close="emit('handleClose', false)">
            <div class="add-changeProtocol-form-box">
                <dc-form
                    ref='dcForm'
                    :row="addChangeProtocolData"
                    :config="FIELDS"
                    :col="2"
                    @data-changed="handleFormDataChange"
                    labelWidth="150px">
                </dc-form>
            </div>
            <table-box
                class="add-changeProtocol-table"
                v-loading="neoCrmOpportunityList.tableLoading"
                ref="tableBox"
                :selection="selection"
                :height="config.height"
                :columns="columnsNew"
                :rows="neoCrmOpportunityList.totalData"
                :highlight-current-row="true"
                :selectable="config.selectable"
                @selection-change="handleSelectedChange"
                @row-click="handleRowClick"
            >
            </table-box>
            <lots-pagination
                @size-change="neoCrmOpportunityList.sizeChange"
                :current-page.sync="neoCrmOpportunityList.pageNo"
                @current-change="neoCrmOpportunityList.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="neoCrmOpportunityList.pageTotal"
                :pageSize="neoCrmOpportunityList.pageSize"
            ></lots-pagination>
            <span slot="footer">
                <el-button type="primary" size="mini" @click="confirm">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { ListClass } from '@/modules/mdm/composition/dc/common/list/ListClass';
import { tableConfig } from './config.js';
import { searchNeoCrmOpportunityApi } from '@mdm/api/contractManage/detailMainApi.js';
import { Message } from 'element-ui';
import dcForm from '@/modules/mdm/components/form/Index.vue';
import store from '@/store';
import { companyList } from '@mdm/api/contractManage/contractFootPageApi.js';
import utils from '@/components/utils/common.js';
const { MAGIC_NUMBER } = utils;
export default {
    components: { dcForm, tableBox, lotsPagination },
    props: {
        addChangeProtocolLoading: {
            type: Boolean,
            default: false
        },
        preChangeProtocolData: {
            type: Object,
            default: () => { return {}; }
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        const emit = setupContext.emit;
        let type = 'fensi';
        class DataDetailClass extends ListClass {
            beforeGetList(condition) {
                if (type === 'fensi') {
                    condition.contractCode = condition.contractCode || condition.mainContract || props.preChangeProtocolData.mainContract;
                } else {
                    if (!condition.projectCode) {
                        condition.projectCode = addChangeProtocolData.value['projectCode'];
                        condition.userCode = store.getters.user.userCode;
                    }
                }
                Reflect.deleteProperty(condition, 'type');
                return condition;
            };
            afterGetList(res) {
                if (type === 'fensi') { // 分司数据
                    neoCrmOpportunityList.totalData.value = [];
                    neoCrmOpportunityList.pageTotal.value = 0;
                    if (+res.code === 0) {
                        neoCrmOpportunityList.totalData.value = res.data.list;
                        neoCrmOpportunityList.pageTotal.value = res.data.totalCount;
                    }
                } else { // 商机数据
                    if (+res.code === 0) {
                        neoCrmOpportunityList.totalData.value = res.data.list.map(item => {
                            item = disabledItemFn(item);
                            return item;
                        });
                        neoCrmOpportunityList.pageTotal.value = res.data.totalCount;
                    }
                }
            }
        };
        const disabledItemFn = (item) => {
            if (item.saleStageId !== '中标确认' && item.saleStageId !== '合同签订') {
                item.disabledBid = true;
            } else {
                const { bidDate, contractSearchDate } = item;
                const bidDateTime = bidDate ? new Date(bidDate).getTime() : null;
                const SearchDateTime = contractSearchDate ? new Date(contractSearchDate).getTime() : null;
                const nowTime = new Date().getTime();
                if (!bidDateTime && !SearchDateTime) {
                    item.disabled30 = true;
                }
                if (bidDateTime) {
                    const flag1 = Math.abs(nowTime - bidDateTime) > (60 * 60 * 24 * MAGIC_NUMBER.NINETY * 1000); // 大于当前3个月（90天）时间范围
                    if (!SearchDateTime) {
                        item.disabled30 = flag1;
                    } else {
                        const flag2 = Math.abs(nowTime - SearchDateTime) > (60 * 60 * 24 * MAGIC_NUMBER.NINETY * 1000); // 大于当前3个月（90天）时间范围
                        item.disabled30 = flag1 && flag2;
                    }
                }
            }
            return item;
        };
        let neoCrmOpportunityList = null;
        neoCrmOpportunityList = new DataDetailClass({
            Api: {
                listApi: companyList
            }
        });
        const projectCodeLocal = ref(props.preChangeProtocolData.projectCode);
        const selection = ref(false);
        onMounted(() => {
            // getOpportunityList(props.preChangeProtocolData, 'fensi');
        });
        const columnsNew = computed(() => {
            return projectCodeLocal.value === props.preChangeProtocolData.projectCode ? config.columnsFensi : config.columns;
        });
        const addChangeProtocolData = ref({
            oilIsGroupCustomer: '',
            contractCharacter: '',
            mainContract: '',
            projectCode: '',
            projectType: '', // 额外参数
            ...props.preChangeProtocolData
        });
        const FIELDS = computed(() => {
            config.fields.forEach(item => {
                if (item.prop === 'projectCode') {
                    if (!item.visible) {
                        neoCrmOpportunityList.totalData.value = []; // 清空商机列表
                        mustCheck = false; // 取消商机必选
                    }
                }
            });
            return config.fields;
        });
        const selectedRows = ref([]);
        const handleSelectedChange = (rows) => {
            selectedRows.value = rows;
        };
        const handleRowClick = (row) => {
            if (row.disabledBid) {
                Message.warning('商机未中标，不允许关联');
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row, false);
            } else if (row.disabled30) {
                Message.warning('商机中标日期超出3个月，请与销售管理沟通后进行关联！');
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row, false);
            } else {
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row); // 不传true, 用于切换选中状态
            }
        };
        let mustCheck = false;
        // eslint-disable-next-line complexity
        const handleFormDataChange = ({ data, form, itemSetting }) => {
            // isCheck:是否校验客户主体 projectType:报价项目类型 isLinkOppo:是否校验关联商机
            const projectKeys = ['projectCode', 'projectStatus', 'isCheck', 'projectType', 'isLinkOppo'];
            if (itemSetting.prop === 'projectCode') {
                neoCrmOpportunityList.totalData.value = [];
                if (!data.projectCode) {
                    projectCodeLocal.value = '';
                    return false;
                }
                projectCodeLocal.value = data.projectCode;
                mustCheck = false; // 初始化商机是否必选
                if (!['6', '8'].includes(form['projectStatus'])) {
                    setTimeout(() => { // 异步解决数据不清空的问题
                        projectKeys.forEach(item => {
                            form[item] = ''; // 清空项目字段信息
                        });
                    });
                    return Message.warning('请关联中标/已签合同项目');
                }
                if (data.projectCode === props.preChangeProtocolData.projectCode) {
                    // 项目与原合同一致，查询原合同(form.mainContract)的分公司明细(表格接口修改成分公司明细接口)
                    neoCrmOpportunityList.listApi = companyList;
                    selection.value = false;
                    getOpportunityList(form, 'fensi');
                } else {
                    neoCrmOpportunityList.listApi = searchNeoCrmOpportunityApi;
                    selection.value = true;
                    // 查询商机
                    // if (+form['isCheck'] === 1 && [1, 2].includes(+form['projectType'])) {
                    //     setTimeout(() => {
                    //         projectKeys.forEach(item => {
                    //             form[item] = '';
                    //         });
                    //     });
                    //     return Message.warning('请选择已完成投标报价的项目');
                    // }
                    if (+form['projectType'] === 1) { // 非标项目
                        form['contractSource'] = '2'; // 合同来源LSRM
                    } else if (+form['projectType'] === 2) { // 标准产品项目
                        form['contractSource'] = '3'; // 合同来源 PMS
                    } else {
                        form['contractSource'] = '1'; // 合同来源 CRM
                    }
                    if (+form['isLinkOppo'] === 1 || !+form['isLinkOppo']) {
                        mustCheck = true; // 需要勾选商机
                    }
                    getOpportunityList(form, 'shangji');
                    addChangeProtocolData.value = Object.assign(addChangeProtocolData.value, form);
                }
            }
        };
        // 获取商机列表函数
        const getOpportunityList = (form, newType) => {
            type = newType;
            if (type === 'fensi') { // 分公司
                neoCrmOpportunityList.getList({
                    type,
                    pageNo: 1,
                    pageSize: 50,
                    contractCode: form['mainContract']
                });
            } else { // 商机
                if (form['projectCode']) {
                    neoCrmOpportunityList.getList({
                        type,
                        pageSize: 50,
                        projectCode: form['projectCode'],
                        userCode: store.getters.user.userCode
                    });
                }
            }
        };
        const confirm = () => {
            if (addChangeProtocolData.value.projectCode !== props.preChangeProtocolData.projectCode) { // 项目编码与原项目不一致时，商机勾选校验
                if (mustCheck && selectedRows.value.length === 0) {
                    return Message.warning('请至少关联一条中标商机');
                }
                setupContext.refs.dcForm.validateForm().then(res => {
                    emit('addNewConfirm', {
                        ...addChangeProtocolData.value,
                        opportunityList: selectedRows.value // 勾选的商机明细
                    });
                });
            } else {
                setupContext.refs.dcForm.validateForm().then(res => {
                    emit('addNewConfirm', {
                        ...addChangeProtocolData.value
                    });
                });
            }
        };
        return {
            emit,
            config,
            neoCrmOpportunityList,
            selectedRows,
            handleSelectedChange,
            handleRowClick,
            confirm,
            handleFormDataChange,
            addChangeProtocolData,
            FIELDS,
            columnsNew,
            projectCodeLocal,
            selection
        };
    }
};
</script>
<style lang="less">
.add-changeProtocol-dialog {
    .add-changeProtocol-form-box {
        width: 100%;
        padding: 0 40px 0 0;
        box-sizing: border-box;
        .el-select {
            width: 100%;
        }
    }
    .add-changeProtocol-table {
        margin: 10px 0 5px 0;
    }
}
</style>
