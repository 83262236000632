import btnAuthority from '@/modules/mdm/btnAuthority.js';
import otherUtils from '@/modules/mdm/utils/otherCommonFunc.js';
const FLOW_STATUS = ['1', '5', '6']; // 草稿、撤回和驳回
export const config = {
    formatProp: [ // 数字/整形类型 字段
        'contractCharacter', 'contractCount', 'bigBusinessType', 'contractStatus', 'oilIsGroupCustomer', 'changeType', 'customerType', 'priceType',
        'stampMode', 'ifWatermarking', 'contractSource', 'productType', 'lowestDiscount', 'receiptRecoveryDays', 'reconciliationInvoicingDays',
        'collectionDays', 'contractAccountOf', 'acceptanceDays', 'professionalCompany', 'oilLinkedProportion', 'insurant', 'isNew', 'paymentCollection',
        'paymentMethod', 'remittanceDate', 'minFeeAmount',
        'receiveTo', 'feePaymentMode', 'settlementmodeMode', 'sealCategory', 'sealedSignatory', 'linkageType', 'freightFloatMode',
        'oilLinkageExecutime', 'newOilSelectionMethod', 'triggerPriceAgain', 'expectedTrafficPlan', 'operationScale', 'grossMarginPlan', 'deposit',
        'acceptanceRate', 'feeRate', 'operatGrossMarginPlan', 'triggerOilPriceFloat', 'freightLinkedScale', 'freightFloatCoefficient'
    ]
};

export const isOtherChangeShow = [ // 其他变更独有的一些字段
    'contractChangeCode',
    'contractChangeStatus',
    'changeDetail',
    'changeReason',
    'changeType'
];

export const isOtherChangeHide = [ // 其他变更时隐藏一些字段
    'contractName', 'serviceManagerName',
    'priceType', 'stampMode', 'sealCategory', 'sealAdminAccount',
    'ifWatermarking', 'noWatermarkingReason', 'contractSource'
];

export const isOtherPriceChangeShow = [ // 其他变更-价格变更时只展示的字段
    'contractChangeCode', 'contractChangeStatus', 'contractCode', 'contractCharacter', 'mainContract', 'changeType', 'changeReason', 'changeDetail', 'businessType',
    'priceMaintainerName', 'priceMaintainerMip', 'version'
];
export const isOtherPriceChangeEdit = [ // 其他变更-价格变更时只编辑的字段
    'changeReason'
];
export const isOtherDateChangeEdit = [ // 其他变更-日期变更时只编辑的字段
    'changeReason', 'validTime', 'failTime'
];
// 流程信息-特权废弃按钮事件
export const actions = {
    fixedWidth: 160,
    list: [
        {
            label: '特权废弃',
            event: 'abandom',
            displayRule: row => {
                // 草稿状态
                const isAbandom = FLOW_STATUS.includes(
                    row.mipStatus?.toString()
                );
                // 权限
                const isAuth = otherUtils.getHasAuth(
                    btnAuthority.contractManage.subFlowAbandom
                );
                return isAbandom && isAuth;
            }
        }
    ]
};
