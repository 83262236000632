export const tableConfig = {
    name: 'addNewMainSearach',
    fields: [
        {
            label: '内外部业务',
            prop: 'oilIsGroupCustomer',
            type: 'select',
            visible: true,
            disabled: true,
            optionsKey: 'SYS_WM_IN_OR_OUT_CUSTOMER'
        },
        {
            label: '合同性质',
            prop: 'contractCharacter',
            type: 'select',
            visible: true,
            disabled: true,
            optionsKey: 'SYS_WM_CONTRACT_CHARACTER',
            options: []
        },
        {
            label: '关联合同',
            prop: 'mainContract',
            value: 'mainContract',
            type: 'input',
            visible: true,
            disabled: true
        },
        {
            label: '关联项目',
            prop: 'projectCode',
            value: 'projectCode',
            type: 'advance',
            visible: true,
            rule: [
                { required: true, message: '项目编码不能为空', trigger: ['change', 'blur'] }
            ],
            advanceConfig: [
                { prop: 'projectCode', value: '项目编码' }
            ],
            tableConfig: [
                { prop: 'projectCode', value: '项目编码' },
                {
                    prop: 'projectStatus',
                    value: '项目状态',
                    label: '项目状态',
                    type: 'select',
                    optionsKey: 'CRM_CUSTOMER_PROJECT_STATUS',
                    options: []
                }
            ],
            prefix: '/api-lcrm',
            advanceUrl: `/cdTsCustomerProjects`,
            cbParams: ['projectCode', 'projectStatus', 'isCheck', 'projectType', 'isLinkOppo']
            // isCheck:是否校验客户主体 projectType:报价项目类型 isLinkOppo:是否校验关联商机
        }
    ],
    height: 325,
    columns: [ // 商机列表
        {
            label: '商机号',
            prop: 'opportunityCode',
            width: 130
        },
        {
            label: '商机名称',
            prop: 'opportunityName',
            width: 130
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 140
        },
        {
            label: '实际中标规模(万)',
            prop: 'totalScale',
            width: 120
        },
        {
            label: '目前规模(万)',
            prop: 'businessScale',
            width: 100
        },
        {
            label: '签单规模(万)',
            prop: 'cdExpectBiddingSize',
            width: 100
        },
        {
            label: '销售阶段',
            prop: 'saleStageId'
        },
        {
            label: '中标日期',
            prop: 'bidDate',
            width: 160
        }
    ],
    columnsFensi: [ // 原合同分公司明细
        {
            label: '商机号',
            prop: 'opportunityCode',
            width: 130
        },
        {
            label: '商机名称',
            prop: 'opportunityName',
            width: 130
        },
        {
            label: '实际中标规模(万)',
            prop: 'totalScale',
            width: 120
        },
        {
            label: '目前规模(万)',
            prop: 'businessScale',
            width: 100
        },
        {
            label: '签单规模(万)',
            prop: 'cdExpectBiddingSize',
            width: 100
        },
        {
            label: '分公司',
            prop: 'companyName',
            width: 140
        },
        {
            label: '服务平台',
            prop: 'siteNames'
        }
    ],
    selectable(row, index) {
        return !row.disabledBid && !row.disabled30;
    }
};
