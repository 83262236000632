<template>
    <div class="change-standard-dialog">
        <el-dialog
            :visible="true"
            width="600px"
            title="转标准产品合同："
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <div class="tips">非标合同转标准产品合同需在PMS重新完成相关测算流</div>
            <div class="footer">
                <lots-button type="primary" size="mini" @click="confirm">跳转PMS</lots-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import lotsButton from '@/components/lots/lotsButton';
import config from '@/config/user.env';
import { keys } from './config';
import _ from 'lodash';
export default {
    components: {
        lotsButton
    },
    props: {
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    setup(props, setupContext) {
        const { emit } = setupContext;
        const env = config.env ? config.env.toLowerCase() : 'sit';
        const newData = _.cloneDeep(props.detailData);
        newData.businessType = newData.businessType.length ? newData.businessType.join(',') : '';
        let querys = '';
        keys.forEach((key, index) => {
            if (newData[key] || newData[key] === 0) {
                let data = newData[key];
                if (key === 'validTime' || key === 'failTime') {
                    data = newData[key].substring(0, 10);
                }
                if (index === keys.length - 1) {
                    querys = querys + (key === 'salesStaffName' ? 'developerName' : key) + '=' + data;
                } else {
                    querys = querys + (key === 'salesStaffName' ? 'developerName' : key) + '=' + data + '&';
                }
            }
        });
        const confirm = () => {
            const pmsUrl = `https://pms${env === 'prod' ? '' : '-' + env}.annto.com/index.html#/mod/standard/standard-detail?${querys}`;
            window.open(pmsUrl, '_blank');
        };
        return {
            emit,
            confirm
        };
    }
};
</script>
<style lang="less">
.change-standard-dialog {
    .tips {
        height: 50px;
    }
    .footer {
        text-align: center;
        margin: 10px 0;
        .el-button {
            padding: 0 20px;
        }
    }
}
</style>
