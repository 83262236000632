<template>
    <div class="company-price-dialog">
        <el-dialog
            :visible="visibleDialog"
            width="500px"
            title="请分配价格维护人"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="visibleDialog = false">
            <div class="change-btn-box">
                <!-- 表单信息组件 -->
                <dc-form
                    ref='dcForm'
                    :row="addNewMainData.value"
                    :config="config.fields"
                    :col="2"
                    @data-changed="handleFormDataChange"
                    labelWidth="150px">
                        <template v-slot:form-append="{form, itemSetting, rIndex}">
                            <el-form-item
                                :label="itemSetting.label"
                                :prop="itemSetting.prop"
                                label-width="150px">
                                <span class="custom-span" :title="form[itemSetting.prop]">
                                    {{ addNewMainData[itemSetting.prop] }}
                                </span>
                            </el-form-item>
                    </template>
                </dc-form>
            </div>
            <span slot="footer" class="text-right">
                <lots-button type="primary" size="mini" @click="confirm">确认</lots-button>
                <lots-button size="mini" @click="visibleDialog = false">取消</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, watch } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import dcForm from '@/modules/mdm/components/form/Index.vue';
import { configFields } from './config.js';
export default {
    components: { lotsButton, dcForm },
    props: {
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup (props, setupContext) {
        const config = reactive(configFields);
        const addNewMainData = ref({
            adminApproved: '',
            priceMaintainerName: '',
            priceMaintainerMip: '',
            companyName: '',
            contractCode: ''
        });
        const visibleDialog = ref(false);
        const emit = setupContext.emit;
        watch(() => props.detailData, val => {
            addNewMainData.value.companyName = val?.companyName;
            addNewMainData.value.contractCode = val.contractCode;
        }, { immediate: true }
        );
        const confirm = () => {
            emit('confirm', addNewMainData.value);
            visibleDialog.value = false;
        };
        const show = () => {
            visibleDialog.value = true;
        };
        const handleFormDataChange = ({ data, form, itemSetting }) => {
            addNewMainData.value.priceMaintainerMip = form.priceMaintainerMip;
            addNewMainData.value.priceMaintainerName = form.priceMaintainerName;
        };
        return {
            emit,
            visibleDialog,
            config,
            addNewMainData,
            confirm,
            show,
            handleFormDataChange
        };
    }
};
</script>
<style lang="less">
.other-change-dialog {
    .chang-tips {
        color: red;
        ul.chang-ul {
            list-style: initial;
            margin-left: 20px;
            li {
                list-style: initial;
            }
            li::marker {
                font-size: 2px; /* 设置圆点大小 */
                margin-right: 4px; /* 设置圆点与文本之间的距离 */
            }
        }
    }
    .change-btn-box {
        display: flex;
        justify-content: center;
        .el-button {
            margin: 10px 20px;
            padding: 0 20px;
        }
    }
}
</style>
