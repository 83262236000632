<template>
    <div class="archive-dialog">
        <el-dialog
            :visible="true"
            width="800px"
            title="请核对合同归档信息"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <el-form
                ref="myFormRef"
                :model="formData"
                label-width="140px"
                :rules="rules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="单据编码：" prop="contractCode">
                            <span>{{ formData.contractCode }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法务合同编码：" prop="mipContractCode">
                            <span>{{ formData.mipContractCode }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="收到纸质合同：" prop="isReceived">
                            <el-select
                                size="mini"
                                v-model="formData.isReceived"
                                clearable
                                placeholder="请选择"
                                @change="(data) => handleChange(data, 'isReceived')">
                                <el-option label="是" :value="1"></el-option>
                                <el-option label="否" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="回收份数：" prop="receivedCopies">
                            <el-input-number v-model="formData.receivedCopies" controls-position="right" :min="0" :disabled="rules['receivedCopies'][0].disabled">
                            </el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生成归档码：" prop="archiveCodeFlag">
                            <el-select
                                size="mini"
                                v-model="formData.archiveCodeFlag"
                                clearable
                                placeholder="请选择"
                                :disabled="rules['archiveCodeFlag'][0].disabled"
                                @change="(data) => handleChange(data, 'archiveCodeFlag')">
                                <el-option label="由系统生成" :value="1"></el-option>
                                <el-option label="按合同编码" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="归档码(示例)：" prop="archiveCode">
                            <span>{{ formData.archiveCode }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="备注：" prop="illustrate">
                            <el-input v-model="formData.illustrate" type="textarea" :maxlength="1024" :showWordLimit="true" :rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="mini" @click="emit('handleClose', false)">取消</el-button>
                <lots-button type="primary" size="mini" @click="confirm">确认归档</lots-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import lotsButton from '@/components/lots/lotsButton';
import { contractArchiveInfoSaveApi } from '@mdm/api/contractManage/detailMainApi.js';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Message } from 'element-ui';
export default {
    components: {
        lotsButton
    },
    props: {
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    // eslint-disable-next-line max-lines-per-function
    setup(props, setupContext) {
        const { emit } = setupContext;
        const rules = ref({
            isReceived: [
                { required: true, message: '收到纸质合同不能为空', trigger: 'change' }
            ],
            receivedCopies: [
                {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (rule.required && !value && value !== 0) {
                            callback(new Error('回收份数不能为空'));
                        } else {
                            callback();
                        }
                    },
                    trigger: ['change', 'blur'],
                    disabled: false
                }
            ],
            archiveCodeFlag: [
                {
                    required: true,
                    validator: (rule, value, callback) => {
                        if (rule.required && !value) {
                            callback(new Error('回收份数不能为空'));
                        } else {
                            callback();
                        }
                    },
                    trigger: 'change',
                    disabled: false
                }
            ],
            illustrate: [
                { required: false, message: '备注不能为空', trigger: ['change', 'blur'] }
            ]
        });
        const formData = reactive({ illustrate: '', ..._.cloneDeep(props.detailData), archiveCodeFlag: 1 });
        const archiveCodeShow = (archiveCodeFlag) => {
            if (+archiveCodeFlag === 2) {
                formData.archiveCode = formData.contractCode;
            } else if (+archiveCodeFlag === 1) {
                formData.archiveCode = `GD${dayjs().format('YYYY-MM-DD').split('-').join('')}xxxx`;
            } else {
                formData.archiveCode = '';
            }
        };
        archiveCodeShow(1);
        const handleChange = (data, prop) => {
            if (prop === 'archiveCodeFlag') {
                archiveCodeShow(+data);
            } else {
                /**
                 * 收到纸质合同isReceived
                 * 回收份数receivedCopies：当收到纸质合同为是时，回收份数清空且可编辑；为否时，回收份数默认为”0“且只读；
                 * 生成归档码archiveCodeFlag：当收到纸质合同为是时，生成归档码默认“由系统生成”且可编辑；为否时，生成归档码默认“按合同编码“且只读；
                 * 备注illustrate：当收到纸质合同为是时，不必填；为否时，备注字段必填
                 */
                const { isReceived } = formData;
                if (isReceived === 1) {
                    rules.value['receivedCopies'][0].disabled = false;
                    formData.receivedCopies = undefined; // 设为null 或''时数字选择器默认赋值0, 所以这里要为undefined
                    rules.value['archiveCodeFlag'][0].disabled = false;
                    formData.archiveCodeFlag = 1;
                    archiveCodeShow(formData.archiveCodeFlag);
                    rules.value['illustrate'][0].required = false;
                } else {
                    rules.value['receivedCopies'][0].disabled = true;
                    formData.receivedCopies = 0;
                    rules.value['archiveCodeFlag'][0].disabled = true;
                    formData.archiveCodeFlag = 2;
                    archiveCodeShow(formData.archiveCodeFlag);
                    rules.value['illustrate'][0].required = true;
                }
            }
        };
        const archiveLoading = ref(false);
        const confirm = () => {
            setupContext.refs.myFormRef.validate((pass) => {
                if (pass) {
                    const params = {
                        contractCode: formData.contractCode,
                        isReceived: formData.isReceived,
                        receivedCopies: formData.receivedCopies,
                        archiveCodeFlag: formData.archiveCodeFlag,
                        illustrate: formData.illustrate
                    };
                    if (+params.isReceived === 1 && +params.receivedCopies === 0) {
                        Message.warning('收到纸质合同时，合同份数不能为0');
                        return false;
                    }
                    archiveLoading.value = true;
                    contractArchiveInfoSaveApi(params).then(res => {
                        if (+res.code === 0) {
                            Message.success('归档成功');
                            emit('confirm', params);
                        }
                    }).finally(() => { archiveLoading.value = true; });
                }
            });
        };
        return {
            rules,
            formData,
            emit,
            handleChange,
            confirm
        };
    }
};
</script>
<style lang="less">
.archive-dialog {
    .footer {
        text-align: center;
        margin: 10px 0;
        .el-button {
            padding: 0 20px;
        }
    }
    .el-select, .el-input-number {
        width: 80%;
    }
}
</style>
