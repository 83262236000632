export const configFields = {
    fields: [
        {
            prop: 'contractCode',
            label: '合同',
            type: 'custom',
            span: 24,
            width: '200px'
        },
        {
            prop: 'companyName',
            label: '分公司',
            type: 'custom',
            span: 24,
            width: '200px'
        },
        {
            label: '价格维护人',
            prop: 'priceMaintainerName',
            value: 'priceMaintainerName',
            name: '添加价格维护人',
            type: 'advanceMulti',
            width: '220px',
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名', 'displayName': 'esusUserNameCn' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#priceMaintainerName', 'esusLoginName#priceMaintainerMip'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            },
            visible: true,
            rule: [
                { required: false, message: '接受价格维护待办不能为空', trigger: 'change' }
            ]
        }
    ]
};
