<template>
    <div class="other-change-dialog">
        <el-dialog
            :visible="true"
            width="500px"
            title="请选择其他变更类型："
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            @close="emit('handleClose', false)">
            <div class="chang-tips">
                <p>郑重提示，请务必认真阅读！</p>
                <ul class="chang-ul">
                    <li>合同修正流程仅限用于合同表单基本信息的修改。</li>
                    <li>如合同/协议内容本身需要调整、变更的，请发起合同补充协议或合同终止流程。</li>
                </ul>
            </div>
            <div class="change-btn-box">
                <el-tooltip
                    v-has="authorityCode.otherChangeBasic"
                    effect="dark"
                    content="适用于修正合同表单基本信息及关联信息：业务类别、客户明细、分公司、服务平台、预签明细、油价联动等。"
                    placement="bottom">
                    <!-- 合同状态：有效时可用 -->
                    <lots-button
                        type="primary"
                        @click="basicChange"
                        :loading="basicChangeLoading"
                        :disabled="![2].includes(+detailData.contractStatus)">基本信息</lots-button>
                </el-tooltip>
                <!-- 日期变更：当合同性质为预签、终止协议时隐藏 -->
                <el-tooltip
                    v-has="authorityCode.otherChangeDate"
                    v-if="!['4', '5'].includes(String(detailData.contractCharacter))"
                    effect="dark"
                    content="适用于合同日期填写错误修正"
                    placement="bottom">
                    <!-- 合同状态：有效、失效时可用 -->
                    <lots-button
                        type="primary"
                        @click="dateChange"
                        :loading="dateChangeLoading"
                        :disabled="![2, 5].includes(+detailData.contractStatus)">合同日期</lots-button>
                </el-tooltip>
                <!-- 价格变更：当合同性质为补充协议、终止协议时隐藏 -->
                <el-tooltip
                    v-has="authorityCode.otherChangePrice"
                    v-if="!['3', '5'].includes(String(detailData.contractCharacter))"
                    effect="dark"
                    content="适用于修正合同关联的价格错误"
                    placement="bottom">
                    <!-- 合同状态：有效、失效时可用 -->
                    <lots-button
                        type="primary"
                        @click="priceChange"
                        :loading="priceChangeLoading"
                        :disabled="!priceShow">价格信息</lots-button>
                </el-tooltip>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api';
import { otherChangeApplyApi } from '@mdm/api/contractManage/detailMainApi.js';
import lotsButton from '@/components/lots/lotsButton';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { CONTRACT_CHARACTER, CONTRACT_STATUS } from '@/modules/mdm/constant/contractManage.js';
export default {
    components: { lotsButton },
    props: {
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const emit = setupContext.emit;
        const authorityCode = reactive(btnAuthority.contractManage);
        const basicChangeLoading = ref(false);
        const priceChangeLoading = ref(false);
        const dateChangeLoading = ref(false);
        const { contractCode } = props.detailData;
        const priceShow = computed(() => {
            // ”草稿/新增/审批中/审批通过/有效/失效“时展示
            // "![2, 5].includes(+detailData.contractStatus)
            // return ![2, 5].includes(+props.detailData.contractStatus);
            // 仅合同性质=”新签/续签/预签“
            const contractCharacterStatus = [+CONTRACT_CHARACTER.NEW, +CONTRACT_CHARACTER.RENEW, +CONTRACT_CHARACTER.ADVANCE].includes(+props.detailData.contractCharacter);
            // 且合同状态=”草稿/新增/审批中/审批通过/有效/失效“时展示
            const contractStatus = [+CONTRACT_STATUS.DRAFT, +CONTRACT_STATUS.APPEND, +CONTRACT_STATUS.EFFECT, +CONTRACT_STATUS.FAILURE,
                +CONTRACT_STATUS.PASS, +CONTRACT_STATUS.APPROVING].includes(+props.detailData.contractStatus);
            return contractCharacterStatus && contractStatus;
        });
        const basicChange = async() => {
            basicChangeLoading.value = true;
            const res = await otherChangeApplyApi({ contractCode, changeType: 0 }); // 校验合同是否可以变更
            basicChangeLoading.value = false;
            if (+res.code === 0) {
                emit('changeApply', 'basic');
            }
            emit('handleClose', false);
        };
        const priceChange = async() => {
            priceChangeLoading.value = true;
            const res = await otherChangeApplyApi({ contractCode, changeType: 1 }); // 校验合同是否可以变更
            priceChangeLoading.value = false;
            if (+res.code === 0) {
                emit('changeApply', 'price');
            } else {
                // 校验报错定位胆其他变更页签
                emit('handlePriceChange', false);
            }
            emit('handleClose', false);
        };
        const dateChange = async() => {
            dateChangeLoading.value = true;
            const res = await otherChangeApplyApi({ contractCode, changeType: 2 }); // 校验合同是否可以变更
            dateChangeLoading.value = false;
            if (+res.code === 0) {
                emit('changeApply', 'date');
            }
            emit('handleClose', false);
        };
        return {
            emit,
            authorityCode,
            basicChangeLoading,
            priceChangeLoading,
            dateChangeLoading,
            priceShow,
            basicChange,
            priceChange,
            dateChange
        };
    }
};
</script>
<style lang="less">
.other-change-dialog {
    .chang-tips {
        color: red;
        ul.chang-ul {
            list-style: initial;
            margin-left: 20px;
            li {
                list-style: initial;
            }
            li::marker {
                font-size: 2px; /* 设置圆点大小 */
                margin-right: 4px; /* 设置圆点与文本之间的距离 */
            }
        }
    }
    .change-btn-box {
        display: flex;
        justify-content: center;
        .el-button {
            margin: 10px 20px;
            padding: 0 20px;
        }
    }
}
</style>
