<!-- eslint-disable max-lines -->
<template>
    <div class="contract-detail"
        :class="{'otherChange-detail': detailPageState === 'otherChange' && [1, 2].includes(+detailDataLocal.changeType)}"
        v-loading="detailLoading">
        <!-- 按钮区域 -->
        <div class="detail-toolBar">
            <span
                class="contract-detail-btn"
                v-if="detailPageState !== 'new' && detailPageState !== 'otherChange'"
                v-has="authorityCode.addNewMain" >
                <lots-button type='primary' @click="addNew">新 增</lots-button>
            </span>
            <span class="contract-detail-btn" v-has="authorityCode.saveMain">
                <lots-button
                    type="primary"
                    :disabled="disableBus['saveHeader']"
                    @click="saveHeader"
                    :loading="detailLoading">保 存</lots-button>
            </span>
            <span v-has="authorityCode.applyIflow" v-if="!['otherChange', 'new'].includes(detailPageState)" class="contract-detail-btn">
                <lots-button type='primary' :disabled="disableBus['submitIFlow']" :loading="applyIflowLoading" @click="applyIflow" class="bigData-btn-detailPageApplyIflow">提交审批</lots-button>
            </span>
            <span v-has="authorityCode.replenishContract" v-if="detailPageState !== 'otherChange' && [1, 2].includes(detailData.contractCharacter)" class="contract-detail-btn">
                <lots-button type='primary' @click="replenishContract" :disabled="disableBus['supplemental']"
                    :loading="replenishContractLoading" class="bigData-btn-detailPageReplenish">补充协议
                </lots-button>
            </span>
            <span v-has="authorityCode.stopContract" v-if="detailPageState !== 'otherChange' && [1, 2].includes(detailData.contractCharacter)" class="contract-detail-btn">
                <lots-button type='primary' @click="stopContract" :loading="stopContractLoading" :disabled="disableBus['termination']" class="bigData-btn-detailPageStopContract">终止协议</lots-button>
            </span>
            <span v-has="authorityCode.otherChange" v-if="!['otherChange', 'new'].includes(detailPageState)" class="contract-detail-btn">
                <lots-button
                    class="bigData-btn-detailPageOtherChange"
                    type='primary'
                    :disabled="disableBus['otherChange']"
                    @click="otherChange"
                    :loading="otherChangeLoading">其他变更</lots-button>
            </span>
            <span v-has="authorityCode.contractChangeSubmit" v-if="detailPageState === 'otherChange'" class="contract-detail-btn">
                <lots-button type="primary" @click="contractChangeSubmit" :disabled="disableBus['contractChangeSubmit']" :loading="contractChangeSubmitLoading">
                  变更提交

                </lots-button>
            </span>
            <span v-has="authorityCode.contractApproved" v-if="detailPageState === 'otherChange' && +detailDataLocal.changeType === 0" class="contract-detail-btn">
                <lots-button type='primary' :disabled="disableBus['ContractApproved']" @click="handleContractApproved" :loading="handleContractApproveLoading">审批通过</lots-button>
            </span>
            <span v-has="authorityCode.contractApprovedReject" v-if="detailPageState === 'otherChange' && +detailDataLocal.changeType === 0" class="contract-detail-btn">
                <lots-button type='primary' :disabled="disableBus['ContractApprovedReject']" @click="handleContractApprovedReject" :loading="handleContractApprovedRejectLoading">审批驳回</lots-button>
            </span>
            <!-- <span v-if="!disableBus['priceMaintenBtn']" class="contract-detail-btn" v-has="authorityCode.priceMaintain">
                <lots-button @click="priceClick" type='primary' :disabled="disableBus['priceMaintenBtn']" :loading="priceLoading">计费模型和价格</lots-button>
            </span> -->
            <span class="contract-detail-btn" v-has="authorityCode.changeStandard" v-if="!['otherChange', 'new'].includes(detailPageState) && !disableBus['changeStandard']&&!isJDSK">
                <lots-button @click="handleChangeStandard" type='primary'>转标准产品合同</lots-button>
            </span>
            <span class="contract-detail-btn" v-has="authorityCode.otherSignature" v-if="isJDSK && disableBus['otherSignature']">
                <lots-button @click="handleOtherSignature" type='primary' :loading="otherLoading">提交第三方签章</lots-button>
            </span>
            <span class="contract-detail-btn" v-has="authorityCode.anntoSignature" v-if="isJDSK && disableBus['anntoSignature']">
                <lots-button @click="handleSignature" type='primary' :loading="signLoading">提交智汇签验签</lots-button>
            </span>
                <span v-has="authorityCode.deleteMain" v-if="!['new'].includes(detailPageState)" class="contract-detail-btn">
                    <lots-button type='primary' :disabled="disableBus['delHeader']" @click="deleteHeader" :loading="deleteHeaderLoading">删除</lots-button>
                </span>
            <el-tooltip
                class="item-flow-right"
                effect="dark"
                content="刷新"
                placement="bottom">
                <lots-button
                    size="mini"
                    icon="el-icon-refresh"
                    v-show="code !== '0000'"
                    class="refresh"
                    @click="refleshPage"
                    :loading="detailLoading"></lots-button>
            </el-tooltip>
            <lots-button
                v-if="detailPageState === 'otherChange' ?
                (['2', '3', '4', '5'].includes(String(detailDataLocal.contractChangeStatus)) && +detailDataLocal.changeType !== 0)
                : (detailDataLocal.contractStatus && +detailDataLocal.contractStatus !== 0)"
                class="item-flow-right view-flow"
                size="mini"
                type='primary'
                :loading="viewFlowLoading"
                @click="handleViewFlow">流程信息</lots-button>
            <lots-button
                v-has="authorityCode.contractArchivePrint"
                v-if="detailDataLocal.archiveCode && +detailData.stampMode === 2"
                class="item-flow-right"
                size="mini"
                type='primary'
                @click="handlePrintArchive">打印归档码</lots-button>
            <lots-button
                v-if="(['2', '5', '7'].includes(detailDataLocal.contractStatus + '') || (detailDataLocal.contractStatus + '' === '6' && +detailData.contractCharacter === 5))
                    && +detailData.stampMode === 2 && !detailDataLocal.archiveCode"
                v-has="authorityCode.contractArchive"
                class="item-flow-right"
                size="mini"
                type='primary'
                @click="handleArchive">合同归档</lots-button>
        </div>
        <!-- 表单信息组件 -->
        <collapse-infos
            ref="upholdHealder"
            :contractCode="detailDataLocal.contractCode"
            :detailData="detailDataLocal"
            :activeCollapses="activeCollapses"
            :collapse="COLLAPSE"
            :detailPageState="detailPageState"
            :disableBus="disableBus"
            @change="handleInfosChange">
               <template v-slot:other-append="{form, itemSetting, rIndex, collapseItem, dictData}">
                <div v-if="itemSetting.prop === 'failTime'">
                    <el-date-picker
                        v-model="form[itemSetting.prop]"
                        type="date"
                        :format="itemSetting.format"
                        :value-format="itemSetting.valueFormat"
                        placeholder="选择日期时间"
                        @change="failTimeChange($event,itemSetting.prop)">
                    </el-date-picker>
                    <span class="tips-red" v-if="disableBus['isShowTimeTips']"></span>
                </div>
            </template>
        </collapse-infos>
        <!-- 子页签组件 -->
        <el-collapse
            class="contract-collapse detail-more-collapse"
            value="moreInfo"
            @change="handleClickMoreInfo">
            <el-collapse-item title="更多信息" name="moreInfo" ref="tabs">
                <el-tabs v-model="activeName" @tab-click="handleClickTab">
                    <el-tab-pane
                        label="附件明细"
                        name="filesDetail"
                        v-if="subTabShow.fileDetail">
                        <file-detail
                            ref="filesDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal">
                        </file-detail>
                    </el-tab-pane>
                    <el-tab-pane label="客户明细" name="customerDetail" v-if="subTabShow.coustomer">
                        <customer-detail
                            ref="customerDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal">
                        </customer-detail>
                    </el-tab-pane>
                    <el-tab-pane label="分公司明细" name="companyDetail" v-if="subTabShow.company">
                        <company-detail
                            ref="companyDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState">
                        </company-detail>
                    </el-tab-pane>
                    <el-tab-pane label="油价信息" name="oilDetail" v-if="subTabShow.oilDetail">
                        <oil-detail
                            ref="oilDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal">
                        </oil-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="业务税率"
                        name="rateDetail"
                        v-if="subTabShow.rate">
                        <rate-detail
                            ref="rateDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal">
                        </rate-detail>
                    </el-tab-pane>
                    <el-tab-pane label="预签明细" name="prescreenDetail" v-if="subTabShow.prescreen">
                        <prescreen-detail
                            ref="prescreenDetailRef"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :detailData="detailDataLocal">
                        </prescreen-detail>
                    </el-tab-pane>
                    <el-tab-pane label="关联合同明细" name="relationDetail" v-if="subTabShow.relationDetail">
                        <relation-detail
                            ref="relationDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            @subTabDbClick="subTabDbClick">
                        </relation-detail>
                    </el-tab-pane>
                    <el-tab-pane label="变更日志" name="changeLog" v-if="false">变更日志</el-tab-pane>
                    <el-tab-pane
                        label="仓库信息"
                        name="warehouseDetail"
                        v-if="subTabShow.warehouseDetail">
                        <warehouse-detail
                            ref="warehouseDetailRef"
                            :contractCode="detailData.contractCode"
                            :contractStatus="String(detailDataLocal.contractStatus)"
                            :activeName="activeName"
                            :detailPageState="detailPageState">
                        </warehouse-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="其他变更"
                        name="otherChangeDetail"
                        v-if="subTabShow.otherChange">
                        <other-change-detail
                            ref="otherChangeDetailRef"
                            :activeName="activeName"
                            :contractCode="detailData.contractCode"
                            :detailPageState="detailPageState"
                            @otherChangeDetail="otherChange">
                        </other-change-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="价格表"
                        name="priceDetail"
                        v-if="subTabShow.price">
                        <price-detail
                            ref="priceDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            @changeApply="changeApply">
                        </price-detail>
                    </el-tab-pane>
                    <el-tab-pane
                        label="流程信息"
                        name="iflowDetail"
                        v-if="subTabShow.iflow">
                        <iflow-detail
                            ref="iflowDetailRef"
                            :detailData="detailDataLocal"
                            :activeName="activeName"
                            :detailPageState="detailPageState"
                            :actions="actions"
                            @getNewIflowList="getNewIflowEmit">
                        </iflow-detail>
                    </el-tab-pane>
                </el-tabs>
            </el-collapse-item>
        </el-collapse>
        <!-- 其他变更弹窗 -->
        <company-price-dialog
            ref="companyPriceRef"
            :detailData="detailDataLocal"
            :detailPageState="detailPageState"
            @confirm="companyPriceConfirm">
        </company-price-dialog>
        <!-- 其他变更弹窗 -->
        <other-change-dialog
            v-if="otherChangeVisible"
            :detailData="detailDataLocal"
            :detailPageState="detailPageState"
            @changeApply="changeApply"
            @handleClose="updateOtherChangeVisible"
            @handlePriceChange="handlePriceChange">
        </other-change-dialog>
        <!-- 转标准产品合同 -->
        <change-standard
            v-if="changeStandardVisible"
            :detailData="detailDataLocal"
            @handleClose="updateChangeStandardVisible">
        </change-standard>
        <!-- 新增补充协议弹窗 -->
        <add-change-protocol
            v-if="addChangeProtocolvisible"
            :addChangeProtocolLoading="addChangeProtocolLoading"
            :preChangeProtocolData="preChangeProtocolData"
            @handleClose="updateAddChangeProtocolVisible"
            @addNewConfirm="addChangeProtocolConfirm">
        </add-change-protocol>
        <!-- 合同归档 -->
        <archive-dialog
            v-if="archiveVisible"
            :detailData="detailDataLocal"
            @handleClose="archiveVisible = false"
            @confirm="archiveConfirm">
        </archive-dialog>
        <!-- 打印归档码弹框 -->
        <print-archive
            v-if="printArchiveVisible"
            :detailData="detailDataLocal"
            @handleClose="printArchiveVisible = false">
        </print-archive>
    </div>
</template>

<script>
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import FileDetail from '@/modules/mdm/views/contractModules/components/fileDetailTab/Index.vue';
import CustomerDetail from '@/modules/mdm/views/contractModules/components/customerDetail/Index.vue';
import CompanyDetail from '@/modules/mdm/views/contractModules/components/companyDetail/Index.vue';
import PrescreenDetail from '@/modules/mdm/views/contractModules/components/prescreenDetail/Index.vue';
import RelationDetail from '@/modules/mdm/views/contractModules/components/relationDetails/Index.vue';
import OilDetail from '@/modules/mdm/views/contractModules/components/oilDetail/Index.vue';
import RateDetail from '@/modules/mdm/views/contractModules/components/rateDetail/Index.vue';
import IflowDetail from '@/modules/mdm/views/contractModules/components/iflowDetail/Index.vue';
import OtherChangeDetail from '@/modules/mdm/views/contractModules/components/otherChangeDetail/Index.vue';
import warehouseDetail from '@/modules/mdm/views/contractModules/components/warehouseDetail/Index.vue';
import priceDetail from '@/modules/mdm/views/contractModules/components/priceDetail/Index.vue';
import btnAuthority from '@/modules/mdm/btnAuthority.js';
import { collapse } from './collapseInfosConfig.js'; // collapse
// import store from '@/store';
// import configurl from '@/config/user.env';
import { STATE_NEW, STATE_MAIN, STATE_OTHER_CHANGE, STATE_REPLENISH, STATE_STOP } from '@/modules/mdm/constant/contractManage.js';
import { getContractInfoByIdApi, contractInfoSaveApi, supplementaryAddApi, createFlowApi, otherChangeAddApi,
    contractChangeDetailApi, contractChangeUpdateStatusApi, contractChangeUpdateContractApi, contractChangeRejectApi,
    searchContractByOtherApi, priceOtherFinsh, pushFileOther, pushFileSignature } from '@mdm/api/contractManage/detailMainApi.js';
import { contractDelete, getByContractCodeApi } from '@mdm/api/contractManage/contractListApi.js';
import { contractChangeDelete, contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import _ from 'lodash';
import { config, isOtherChangeShow, isOtherChangeHide, isOtherPriceChangeShow, actions } from './config.js';
import { MessageBox } from 'element-ui';
import bigDataLog from '@/utils/bigDataLog.js';
import otherChangeDialog from './otherChangeDialog/Index.vue';
import companyPriceDialog from './companyPriceDialog/Index.vue';
import addChangeProtocol from './changeProtocol/Index.vue';
import changeStandard from './changeStandard/Index.vue';
import archiveDialog from './archiveDialog/Index.vue';
import printArchive from './printArchive/Index.vue';
import utils from '@/components/utils/common.js';
const { MAGIC_NUMBER } = utils;
const contractSourceForJDSK = '6'; // 合同来源京东数科
export default {
    name: '',
    components: {
        lotsButton,
        CollapseInfos,
        FileDetail,
        CustomerDetail,
        CompanyDetail,
        PrescreenDetail,
        RelationDetail,
        OilDetail,
        RateDetail,
        IflowDetail,
        OtherChangeDetail,
        warehouseDetail,
        priceDetail,
        otherChangeDialog,
        addChangeProtocol,
        changeStandard,
        archiveDialog,
        printArchive,
        companyPriceDialog
    },
    props: {
        code: { // 页面识别码（可能是contractCode 或者 contractChangeCode: 当页面是其他变更并且有变更编号时）
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            default() {
                return {};
            }
        },
        detailPageState: {
            // 是否跳转变更页传递了个页面状态过来
            type: String,
            default() {
                return '';
            }
        }
    },
    data() {
        return {
            detailLoading: false,
            stopContractLoading: false,
            replenishContractLoading: false,
            otherChangeLoading: false,
            contractChangeSubmitLoading: false,
            handleContractApproveLoading: false,
            handleContractApprovedRejectLoading: false,
            deleteHeaderLoading: false,
            priceLoading: false,
            activeName: 'filesDetail',
            collapse: _.cloneDeep(collapse),
            detailDataLocal: _.cloneDeep(this.formatData(this.detailData)),
            authorityCode: btnAuthority.contractManage,
            applyIflowLoading: false,
            isHandleEdit: false,
            // projectDisabled: false,
            viewFlowUrl: '',
            viewFlowLoading: false,
            otherChangeVisible: false,
            iflowList: [],
            fdId: '',
            addChangeProtocolvisible: false,
            addChangeProtocolLoading: false,
            preChangeProtocolData: {},
            changeStandardVisible: false,
            archiveVisible: false,
            printArchiveVisible: false,
            actions,
            isShowOtherChangeDialog: false,
            isJDSK: false, // 判断是否为JD数科
            signLoading: false, // 智汇签章
            otherLoading: false // 第三方签章
        };
    },
    watch: {
        detailData: {
            handler (val) {
                this.refleshPage();
            },
            deep: true
        }
    },
    computed: {
        disableBus () {
            // changeType 0：基础信息， 1：价格信息, 2：日期变更 ，变更状态 SYS_LMDM_CONTRACT_CHANGE
            const disableBus = {
                addNew: false, // 新增
                saveHeader: !this.saveHeaderFlag, // 保存
                submitIFlow: !([0, 1].includes(+this.detailDataLocal.contractStatus) && ![STATE_NEW].includes(this.detailPageState)), // 提交审批
                supplemental: !([2].includes(+this.detailDataLocal.contractStatus) && [1, 2].includes(+this.detailDataLocal.contractCharacter)), // 补充协议
                termination: !([2].includes(+this.detailDataLocal.contractStatus) && [1, 2].includes(+this.detailDataLocal.contractCharacter)), // 终止协议
                otherChange: !([0, 1, 2, 5, 6, 9].includes(+this.detailDataLocal.contractStatus) && ![STATE_OTHER_CHANGE].includes(this.detailPageState)), //  其他变更 contractStatus 草稿 新增 生效 失效 审批通过  审批中
                contractChangeSubmit: ![1, 5].includes(+this.detailDataLocal.contractChangeStatus), // 基础信息、价格信息、价格变更：状态不为新建时禁用
                ContractApproved: ![2].includes(+this.detailDataLocal.contractChangeStatus), // 合同审批通过
                ContractApprovedReject: ![2].includes(+this.detailDataLocal.contractChangeStatus), // 合同审批驳回
                delHeader: !this.delHeaderFlag, // 删除
                priceMaintenBtn: !([1, 2, 4].includes(+this.detailDataLocal.contractCharacter) && ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState)), // 价格维护按钮;
                changeStandard: ![2, 5].includes(+this.detailDataLocal.contractStatus), // 有效、失效
                otherSignature: [2, 6, 9].includes(+this.detailDataLocal.contractStatus), // 审批中、审批通过、有效
                anntoSignature: [2, 6, 9].includes(+this.detailDataLocal.contractStatus), // 审批中、审批通过、有效
                isShowTimeTips: [0].includes(+this.detailDataLocal.contractStatus) && [5].includes(+this.detailDataLocal.contractCharacter) // 草稿 && 终止协议

            };
            return disableBus;
        },
        saveHeaderFlag() {
            if (this.detailPageState === STATE_OTHER_CHANGE) {
                if (this.detailDataLocal.contractChangeCode) {
                    return [1, 5].includes(+this.detailDataLocal.contractChangeStatus); // 基础信息、价格信息、价格变更：状态为新建时可保存
                } else {
                    return true;
                }
            } else {
                return [0, 1].includes(+this.detailDataLocal.contractStatus);
            }
        },
        delHeaderFlag() {
            if (this.detailPageState === STATE_OTHER_CHANGE) {
                if (this.detailDataLocal.contractChangeCode) {
                    return +this.detailDataLocal.contractChangeStatus === 1; // 新建
                } else {
                    return false;
                }
            } else {
                return [0].includes(+this.detailDataLocal.contractStatus);
            }
        },
        // eslint-disable-next-line complexity
        subTabShow() {
            return {
                fileDetail: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) // 其他变更：基础信息变更不展示
                    : ![STATE_NEW].includes(this.detailPageState), // 新增：不展示
                coustomer: this.detailPageState === STATE_OTHER_CHANGE ? true // 其他变更:展示
                    : ![STATE_NEW].includes(this.detailPageState), // 新增：不展示
                company: true,
                prescreen: this.detailPageState === STATE_OTHER_CHANGE ? true // 其他变更:展示
                    : ![STATE_NEW].includes(this.detailPageState) && String(this.detailDataLocal.contractCharacter) !== '4', // 新签、预签不展示
                relationDetail: [STATE_MAIN].includes(this.detailPageState),
                oilDetail: this.detailPageState === STATE_OTHER_CHANGE ? String(this.detailDataLocal.oilLinkedProportion) !== '0' // 其他变更: 油价联动为否不展示
                    : String(this.detailDataLocal.oilLinkedProportion) !== '0' && ![STATE_NEW].includes(this.detailPageState), // 新签、油价联动为否不展示
                rate: ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState),
                otherChange: ![STATE_OTHER_CHANGE, STATE_NEW].includes(this.detailPageState),
                iflow: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) : ![STATE_NEW].includes(this.detailPageState),
                warehouseDetail: false,
                price: this.detailPageState === STATE_OTHER_CHANGE ? [1, 2].includes(+this.detailDataLocal.changeType) : ![STATE_NEW].includes(this.detailPageState)
            };
        },
        // eslint-disable-next-line
        COLLAPSE() { // 配置项动态计算
            const {
                contractCharacter, stampMode, oilLinkedProportion, freightFloatMode, settlementMode, ifWatermarking,
                paymentCollection, oilIsGroupCustomer, businessType, accountEntityName, contractSource, changeType
            } = this.detailDataLocal;
            // if (this.detailPageState === STATE_OTHER_CHANGE && String(changeType) === '1') { // 价格变更的独有配置
            //     const newCollapse = this.collapse.find(item => item.formName === 'basicInfo');
            //     newCollapse.formList.forEach((v2, i2) => {
            //         v2.disabled = v2.prop !== 'changeReason';
            //         v2.visible = isOtherPriceChangeShow.includes(v2.prop);
            //         if (v2.prop === 'mainContract') { // 主合同编号: 固定禁用；合同性质为续签、补充、终止时展示;
            //             v2.visible = ['2', '3', '5'].includes(String(contractCharacter));
            //         }
            //         if (v2.rule && v2.rule.length) {
            //             v2.rule[0].required = !v2.disabled;
            //         }
            //         if (v2.prop === 'businessType') {
            //             v2.type = v2.disabled ? 'custom' : 'select';
            //         }
            //     });
            //     return [newCollapse];
            // }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties

            // eslint-disable-next-line max-lines-per-function
            this.collapse.forEach((v, i) => {
                // eslint-disable-next-line complexity, max-lines-per-function
                v.formList.forEach((v2, i2) => {
                    // 基础信息
                    if (isOtherChangeShow.includes(v2.prop)) {
                        v2.visible = this.detailPageState === STATE_OTHER_CHANGE;
                    }
                    if (isOtherChangeHide.includes(v2.prop)) {
                        v2.visible = this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    // if (v2.prop === 'contractCharacter') { // 新增合同，合同性质可编辑
                    //     v2['disabled'] = this.detailPageState !== STATE_NEW;
                    // }
                    if (v2.prop === 'mainContract') { // 主合同编号: 固定禁用；合同性质为续签、补充、终止是展示;
                        // v2.rule[0].required = ['2'].includes(String(contractCharacter));
                        v2.visible = ['2', '3', '5'].includes(String(contractCharacter));
                    }
                    if (v2.prop === 'accountEntityName') { // 补充/终止协议/其他变更，禁用会计主体
                        v2.disabled = [STATE_REPLENISH, STATE_STOP, STATE_OTHER_CHANGE].includes(this.detailPageState) || (this.detailPageState === STATE_NEW && String(contractCharacter) === '3');
                        v2.type = v2.disabled ? 'custom' : 'advance';
                    }
                    if (['validTime', 'failTime'].includes(v2.prop) && this.detailPageState === STATE_OTHER_CHANGE) {
                        // 当为其他变更时，合同性质为预签4, 且基础变更时，生效日期、失效日期可编辑, 或变更类型为日期变更时可编辑，其他情况只读
                        v2.disabled = String(changeType) === '0' ? String(contractCharacter) !== '4' : String(changeType) !== '2';
                        v2.type = v2.disabled ? 'custom' : 'time';
                    } else if (v2.prop === 'failTime') {
                        const colType = String(contractCharacter) === '5' && [0].includes(+this.detailDataLocal.contractStatus); // 合同终止协议
                        v2.type = colType ? 'custom' : 'time';
                        v2.customSlot = colType ? 'other-append' : null;
                    }
                    if (v2.prop === 'projectCode') {
                        const stateStatus = [STATE_OTHER_CHANGE, STATE_STOP];
                        v2.rule[0].required = String(oilIsGroupCustomer) === '1' && !stateStatus.includes(this.detailPageState) && !this.isJDSK; // 项目编码：内外部业务为外部1 且为 非变更合同/终止协议 时，必填;
                        // CRM 且 新签、续签、预签、非变更可编辑
                        v2.disabled = !(['1', '2', '4'].includes(String(contractCharacter)) && this.detailPageState !== STATE_OTHER_CHANGE && String(contractSource) === '1');
                        v2.type = v2.disabled ? 'custom' : 'advance';
                    }
                    if (v2.prop === 'customerType') { // 仓储运作类型：业务类别包含‘仓储’才展示
                        const businessTypeList = businessType || [];
                        let hasWarehouse = false;
                        if (businessTypeList.length) {
                            businessTypeList.forEach(item => {
                                if (item === '1') hasWarehouse = true;
                            });
                        }
                        v2.visible = hasWarehouse;
                    }
                    if (v2.prop === 'priceType') { // 价格情况：合同性质不为预签 且不为其他变更才展示
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'stampMode') { // 盖章方式：合同性质不为预签 且不为其他变更才展示
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'sealCategory') { // 印章类型：会计主体有数据，才可编辑; 合同性质不为预签，不为其他变更，盖章方式不为电子 才展示
                        v2.disabled = !accountEntityName;
                        v2.type = v2.disabled ? 'custom' : 'select';
                        v2.placeholder = accountEntityName ? '请选择' : '请先选择会计主体';
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(stampMode) !== '1';
                    }
                    if (v2.prop === 'sealAdminAccount') { // 印章管理员展示：合同性质不为预签，不为其他变更，盖章方式不为电子
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(stampMode) !== '1';
                    }
                    if (v2.prop === 'ifWatermarking') { // 是否生成水印: 合同性质不为预签，不为其他变更
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE;
                    }
                    if (v2.prop === 'noWatermarkingReason') { // 不生成水印原因: 合同性质不为预签，不为其他变更, 并且是否生成水印为否
                        v2.visible = String(contractCharacter) !== '4' && this.detailPageState !== STATE_OTHER_CHANGE && String(ifWatermarking) === '0';
                    }
                    if (v2.prop === 'isCloudWh') { // 是否云仓: 当内外部业务为内部0时必填，外部时不必填且隐藏
                        v2.visible = String(oilIsGroupCustomer) === '0';
                    }
                    if (v2.prop === 'professionalCompany') { // 专业公司: 当合同来源为非CRM时只读, 内外部业务为内部0 时隐藏
                        v2.disabled = String(contractSource) !== '1';
                        v2.type = v2.disabled ? 'custom' : 'select';
                        v2.visible = String(oilIsGroupCustomer) !== '0';
                    }
                    if (v2.prop === 'productType') { // 产品类型：来源系统非CRM, 或合同状态为有效、失效时只读
                        v2.disabled = String(contractSource) !== '1' || [2, 5].includes(+this.detailDataLocal.contractStatus);
                        v2.type = v2.disabled ? 'custom' : 'select';
                        v2.rule[0].required = !v2.disabled;
                    }
                    if (v2.prop === 'businessType') { // 业务类别：日期变更，价格变更只读
                        v2.disabled = this.detailPageState === STATE_OTHER_CHANGE && ['1', '2'].includes(String(changeType));
                        v2.type = v2.disabled ? 'custom' : 'select';
                        // 多选必填设置放在CollapseInfos组件，延后设置，解决初始化出发为空校验提示
                        // v2.rule[0].required = !(this.detailPageState === STATE_OTHER_CHANGE && +changeType !== 0);
                    }
                    if (v2.prop === 'archiveCode' || v2.prop === 'receivedCopies') {
                        v2.visible = +this.detailData.stampMode === 2;
                    }
                    // 财务信息
                    const NotCRM = [ 'contractAccountOf', 'grossMarginPlan', 'operatGrossMarginPlan', 'deposit', 'settlementMode', 'acceptanceRate', 'acceptanceDays' ];
                    if (NotCRM.includes(v2.prop)) { // 合同全账期中标采购毛利率/中标项目经营中心利润率/押金/结算方式/承兑比例/承兑期限：来源系统非CRM只读
                        v2.disabled = String(contractSource) !== '1';
                        v2.type = v2.disabled ? 'custom' : (v2.prop === 'settlementMode' ? 'select' : 'input');
                    }
                    if (v2.prop === 'expectedTrafficPlan') { // 预计业务量：来源系统非CRM 且不为终止协议，只读
                        v2.disabled = String(contractSource) !== '1' && String(contractCharacter) !== '5';
                        v2.type = v2.disabled ? 'custom' : 'input';
                    }
                    if (v2.prop === 'acceptanceRate') { // 承兑比例：结算方式选择 现汇+承兑3 或承兑2 时，必填; 来源系统非CRM只读
                        v2.rule[0].required = ['2', '3'].includes(String(settlementMode));
                    }
                    if (v2.prop === 'acceptanceDays') { // 承兑期限: 现汇+承兑3 或承兑2 时，必填; 来源系统非CRM只读
                        v2.rule[0].required = ['2', '3'].includes(String(settlementMode));
                    }
                    if (['remittanceDate', 'receiveTo', 'feeRate', 'feePaymentMode', 'minFeeAmount'].includes(v2.prop)) { // 汇款时间/收款至/服务费率(%)/服务费支付方式: 是否代收货款 为 是时必填
                        v2.rule[0].required = String(paymentCollection) === '1';
                    }
                    if (v2.prop === 'remittanceDate') { // 汇款时间:
                        v2.visible = v2.rule[0].required;
                    }
                    if (v2.prop === 'minFeeAmount') { // 最低服务费:
                        v2.visible = v2.rule[0].required;
                    }
                    // 油价信息
                    if (v2.prop === 'oilLinkedProportion') { // 预签和终止协议禁用油价联动，默认为否;
                        v2.disabled = ['4', '5'].includes(String(contractCharacter));
                        v2.type = v2.disabled ? 'custom' : 'select';
                    }
                    const isShowoilLinkedArr = [ // 是否油价联动字段直接控制 显示/隐藏的字段
                        'linkageType', 'triggerOilPriceFloat', 'freightFloatMode', 'oilLinkageExecutime',
                        'newOilSelectionMethod', 'triggerPriceAgain', 'conditionsDetail'
                    ];
                    if (isShowoilLinkedArr.includes(v2.prop)) {
                        v2.visible = String(oilLinkedProportion) !== '0';
                    }
                    if (v2.prop === 'freightLinkedScale') { // 运价联动比例(%)： 运价浮动方式 为 联动比例2 展示
                        v2.visible = String(freightFloatMode) === '2' && String(oilLinkedProportion) !== '0';
                    }
                    if (v2.prop === 'freightFloatCoefficient') { // 运价浮动系数(%)： 运价浮动方式 为 浮动系数1 展示
                        v2.visible = String(freightFloatMode) === '1' && String(oilLinkedProportion) !== '0';
                    }
                    // 增量10.21版本: 其他变更：日期变更2/价格变更1其余字段只读字段
                    if (['1', '2'].includes(String(changeType))) {
                        if (!['validTime', 'failTime', 'changeReason', 'changeDetail'].includes(v2.prop)) {
                            v2.disabled = true;
                            v2.type = 'custom';
                        }
                        if (v2.rule) {
                            v2.rule[0].required = !v2.disabled;
                        }
                    }
                    if (v2.prop === 'priceMaintainerName') { // 价格信息变更才显示
                        v2.disabled = false;
                        v2.type = 'advanceMulti';
                        v2.visible = ['1'].includes(String(changeType));
                    }
                });
                if (v.formName === 'bankInfo') {
                    v.visible = +contractSource === 6;
                }
            });
            return this.collapse;
        },
        activeCollapses() {
            const { contractSource } = this.detailDataLocal;
            const collapse = ['basicInfo'];
            if (+contractSource === 6) {
                collapse.push('bankInfo');
            } else {
                collapse.push('financeInfo', 'oilPriceInfo');
            }
            return collapse;
        }
    },
    mounted() {
        bigDataLog.sendDetailMessageByKey('bigData-page-detailPage');
        this.refleshPage();
        if (this.detailPageState === STATE_NEW) {
            this.activeName = 'companyDetail';
        }
    },
    methods: {
        formatData(data) { // 服务类型字符串数组转换函数
            if (data.businessType && typeof data.businessType === 'string') {
                data.businessType = data.businessType.split(',') || [];
            }
            data.validTime && (data.validTime = utils.formatDate(data.validTime));
            data.failTime && (data.failTime = utils.formatDate(data.failTime));
            return data;
        },
        async refleshPage () {
            this.isHandleEdit = false;
            if (this.detailPageState !== STATE_OTHER_CHANGE && this.detailData.id) {
                // 请求详情数据
                this.detailLoading = true;
                getContractInfoByIdApi({ id: this.detailData.id }).then(res => {
                    if (+res.code === 0 && res.data) {
                        this.dataInit(res.data);
                    }
                }).finally(() => {
                    this.detailLoading = false;
                });
            } else if (this.detailPageState === STATE_OTHER_CHANGE && this.detailData.contractChangeCode) {
                this.detailLoading = true;
                // contractChangeDetailApi({ id: this.detailData.id }).then(res => {
                //     if (res.code === '0') {
                //         this.dataInit(res.data);
                //     }
                // }).finally(() => {
                //     this.detailLoading = false;
                // });
                Promise.all([contractChangeDetailApi({ id: this.detailData.id }), getByContractCodeApi({ contractCode: this.detailData.contractCode })])
                    .then(resArr => {
                        if (+resArr[0].code === 0 && +resArr[1].code === 0) {
                            this.dataInit({ ...resArr[1].data, ...resArr[0].data });
                        }
                    }).finally(() => {
                        this.detailLoading = false;
                    });
            } else {
                await this.dataInit(this.detailData);
            }
            if (+this.detailData.todoType === 1 && ['1'].includes(String(this.detailDataLocal.changeType))
                && !this.detailDataLocal.priceMaintainerMip && !this.isShowOtherChangeDialog) {
                this.$refs.companyPriceRef.show();
                this.isShowOtherChangeDialog = true; // 只打开一次
            }
        },
        dataInit(data) {
            const newData = this.formatData(data);
            if ([STATE_OTHER_CHANGE, STATE_REPLENISH, STATE_STOP].includes(this.detailPageState)) {
                if (this.detailPageState === STATE_OTHER_CHANGE && +data.changeType !== 0) {
                    this.activeName = ['2', '3', '4', '5'].includes(String(data.contractChangeStatus)) ? 'iflowDetail' : (+data.changeType === 1 ? 'priceDetail' : 'companyDetail');
                } else {
                    this.activeName = 'customerDetail';
                }
            }
            // if (newData.projectCode) { // 项目编码后端有值，就禁用编辑
            //     this.projectDisabled = true;
            // }
            if (['4', '5'].includes(String(newData.contractCharacter))) { // 合同性指为预签/终止协议，油价联动固定为否
                newData['oilLinkedProportion'] = '0';
            }
            this.isJDSK = +data.contractSource === +contractSourceForJDSK;
            this.handleInfosChange({ form: newData || {} });
        },
        handleInfosChange(formObjData = {}) { // 表单组件编辑回调，更新本地数据
            this.detailDataLocal = _.cloneDeep(formObjData.form);
            const { itemSetting } = formObjData;
            if (itemSetting) { this.isHandleEdit = true; } // 监控表单是否编辑过
            if (!itemSetting) {
                this.$refs.upholdHealder.refleshPage(this.detailDataLocal); // 刷新表单子组件
            }
        },
        saveDataFormat (data) { // 保存的数据格式转化函数
            const integerKey = config.formatProp;
            // 整形、浮点型 格式参数转化
            integerKey.forEach(item => {
                if (data[item] || String(data[item]) === '0') {
                    data[item] = Number(data[item]);
                } else {
                    data[item] = null;
                }
            });
            const businessType = data.businessType;
            data.businessType = businessType.length ? businessType.join(',') : '';
            this.COLLAPSE.forEach((v, i) => {
                v.formList.forEach((v2, i2) => {
                    if (!v2.visible && v2.prop !== 'contractSource') {
                        data[v2.prop] = null; // 清空隐藏的字段值
                    }
                });
            });
            if (this.detailPageState === STATE_OTHER_CHANGE && String(data.changeType) === '1') {
                const newData = { id: data.id };
                isOtherPriceChangeShow.forEach(key => {
                    newData[key] = data[key];
                });
                return newData;
            } else {
                return data;
            }
        },
        // eslint-disable-next-line complexity, max-lines-per-function
        async saveHeader() {
            // 主合同保存校验
            const data = _.cloneDeep(this.$refs.upholdHealder.onsave());
            if (!data) return;
            if (this.detailPageState === STATE_NEW) { // 新增合同保存时
                // 分公司明细检验及处理
                const companyData = this.$refs.companyDetailRef.editTableTotalData;
                // const checkTableData = _.cloneDeep(companyData).map(item => {
                //     delete item.backup;
                //     delete item.edit;
                //     return item;
                // });
                // if (!this.$refs.companyDetailRef.$refs.editTableBox.tableExamine(checkTableData)) { // 编辑表格数据校验
                //     return false;
                // };
                let companyInfos = [];
                const passFlag = {
                    all: true,
                    company: true,
                    site: true,
                    isPureWarehouse: true
                };
                if (companyData.length) {
                    const { businessType } = this.detailDataLocal;
                    const mustFillWarehouse = Boolean(businessType && businessType.length
                        && businessType.some(item => (item === '1' || item === '2')));
                    companyData.forEach((item) => {
                        if (!item.companyCode) {
                            passFlag.company = false;
                        } else if (!item.site || !item.site.length) {
                            passFlag.site = false;
                        }
                        if (mustFillWarehouse && !item.isPureWarehouse) {
                            passFlag.isPureWarehouse = false;
                        }
                    });
                }
                if (!companyData.length || (!passFlag.company && !passFlag.site)) {
                    passFlag.all = false;
                }
                if (!passFlag.all || !passFlag.company || !passFlag.site || !passFlag.isPureWarehouse) {
                    this.activeName = 'companyDetail';
                    const element = document.getElementById('pane-0000new');
                    element.scrollTo(0, MAGIC_NUMBER.ONE_THOUSAND_AND_ONE_HUNDRED); // 页面滚动到底部
                    if (!passFlag.all) {
                        this.$message({
                            message: '请填写分公司明细的分公司与服务平台！',
                            type: 'warning'
                        });
                        return false;
                    } else if (!passFlag.company) {
                        this.$message({
                            message: '请填写分公司明细的分公司！',
                            type: 'warning'
                        });
                        return false;
                    } else if (!passFlag.site) {
                        this.$message({
                            message: '请填写分公司明细的服务平台！',
                            type: 'warning'
                        });
                        return false;
                    } else if (!passFlag.isPureWarehouse) {
                        this.$message({
                            message: '请填写分公司明细的是否纯仓！',
                            type: 'warning'
                        });
                        return false;
                    }
                    return false;
                }
                companyInfos = companyData.map(item => {
                    item.site.map(siteItem => {
                        return {
                            siteCode: siteItem.siteCode,
                            siteName: siteItem.siteName
                        };
                    });
                    return {
                        opportunityCode: item.opportunityCode || null,
                        opportunityName: item.opportunityName || null,
                        totalScale: item.totalScale || null,
                        businessScale: item.businessScale || null,
                        cdExpectBiddingSize: item.cdExpectBiddingSize || null,
                        companyName: item.companyName,
                        companyCode: item.companyCode,
                        site: item.site,
                        isPureWarehouse: item.isPureWarehouse,
                        warehouseType: item.warehouseType
                    };
                });
                data.companyInfos = companyInfos;
            }
            Reflect.deleteProperty(data, 'opportunityList'); // 删除初始商机列表
            const formDataObj = this.saveDataFormat(data); // 参数格式转化
            this.detailLoading = true;
            if (this.detailPageState !== STATE_OTHER_CHANGE) {
                contractInfoSaveApi(formDataObj).then(resSave => {
                    if (resSave.code === '0') {
                        this.$message.success('保存成功');
                        if (this.detailPageState === STATE_NEW) { // 新增保存，更新页面所有信息
                            getContractInfoByIdApi({ id: resSave.data.id }).then(res => {
                                this.$emit('save-data', res.data, this.detailPageState);
                            });
                        } else { // 非新增保存，在当前页刷新数据
                            this.refleshPage();
                            this.activeName = 'filesDetail';
                            // 清空附件明细草稿数据
                            this.$refs.filesDetailRef.addDataArr = [];
                            this.$refs.filesDetailRef.subTabTable.getList();
                        }
                    }
                }).finally(() => {
                    this.detailLoading = false;
                });
            } else {
                formDataObj.id = formDataObj.contractChangeCode ? formDataObj.id : null; // 新增的变更保存，要去掉主合同带来的id
                otherChangeAddApi(formDataObj).then(res => {
                    if (res.code === '0') {
                        this.$message.success('保存成功');
                        if (!formDataObj.contractChangeCode) { // 新增变更保存，更新页面所有信息
                            contractChangeDetailApi({ id: res.data.id }).then(res => {
                                this.$emit('save-data', res.data, this.detailPageState);
                            });
                        } else { // 非新增变更保存，在当前页刷新数据
                            this.refleshPage();
                            this.activeName = 'filesDetail';
                        }
                    }
                }).finally(() => {
                    this.detailLoading = false;
                });
            }
        },
        addNew() {
            this.$emit('add-new');
        },
        async replenishContract() { // 补充协议
            const { oilIsGroupCustomer, contractCode } = this.detailDataLocal;
            if (+oilIsGroupCustomer === 1) { // 内外部业务为外部：弹框
                this.preChangeProtocolData.oilIsGroupCustomer = '1';
                this.preChangeProtocolData.contractCharacter = '3';
                this.preChangeProtocolData.mainContract = contractCode;
                // this.preChangeProtocolData.projectCode = projectCode; // 不带出项目编码
                this.updateAddChangeProtocolVisible(true);
            } else {
                this.supplementaryAddFn();
            }
        },
        // 补充协议校验生成接口
        supplementaryAddFn() {
            const params = {
                id: this.detailDataLocal.id,
                contractCode: this.detailDataLocal.contractCode,
                contractCharacter: 3
            };
            this.replenishContractLoading = true;
            return supplementaryAddApi(params).then(res => {
                if (+res.code === 0 && res.data) {
                    const newPageData = Object.assign({}, this.detailDataLocal, res.data);
                    this.$emit('replenish-contract', newPageData.contractCode, newPageData, 'replenish'); // 打开补充协议页面
                }
                return +res.code === 0;
            }).finally(() => (this.replenishContractLoading = false));
        },
        // 补充协议弹框
        updateAddChangeProtocolVisible(val) {
            this.addChangeProtocolvisible = val;
        },
        // 补充协议弹框确认回调
        async addChangeProtocolConfirm(backData) {
            // backData.opportunityList 勾选的商机明细列表
            if (backData.projectCode === this.detailDataLocal.projectCode) {
                this.supplementaryAddFn().then(res => {
                    if (res) {
                        this.updateAddChangeProtocolVisible(false); // 关闭新增弹框
                    }
                });
            } else {
                // 走商机-预填充补充协议逻辑
                let newPageData = { buChong: true };
                const newdetailDataLocal = _.cloneDeep(this.detailDataLocal);
                newdetailDataLocal.oilLinkedProportion = newdetailDataLocal.oilLinkedProportion ? '1' : '0';
                // 清空不需要从主合同带出的字段
                ['id', 'contractCode', 'contractStatus', 'version', 'validTime', 'failTime', 'stampMode', 'sealCategory', 'sealAdminName', 'sealAdminAccount', 'expectedTrafficPlan'].forEach(key => {
                    newdetailDataLocal[key] = null;
                });
                if (['2', '3'].includes(backData.contractSource)) { // (2.LSRM，3.PMS)：调接口预填充
                    this.addChangeProtocolLoading = true;
                    const res = await searchContractByOtherApi({
                        projectCode: backData.projectCode,
                        projectType: backData.projectType
                    });
                    this.addChangeProtocolLoading = false;
                    if (+res.code === 0) {
                        newPageData = Object.assign(newPageData, newdetailDataLocal, res.data, backData);
                        this.$emit('add-new', newPageData);
                        this.$nextTick(() => {
                            this.updateAddChangeProtocolVisible(false); // 关闭新增弹框
                        });
                    }
                } else { // (1.CRM)：不预填充，直接打开空页面
                    newPageData = Object.assign(newPageData, newdetailDataLocal, backData);
                    this.$emit('add-new', newPageData);
                    this.$nextTick(() => {
                        this.updateAddChangeProtocolVisible(false); // 关闭新增弹框
                    });
                }
            }
        },
        async otherChange(data) { // 其他变更
            const newPageData = data.id ? data : Object.assign({}, this.detailData);
            if (!data.id) { // 非子页签跳转(主合同详情页点击其他变更按钮)
                this.otherChangeVisible = true;
                // const { contractCode, contractStatus } = this.detailDataLocal;
                // const res = await otherChangeApplyApi({ contractCode, contractStatus: String(contractStatus) }); // 校验合同是否可以变更
                // if (res.code !== '0') return;
                // this.otherChangeLoading = true;
                // const newDataRes = await getContractInfoByIdApi({ id: this.detailData.id }); // 重新请求主合同数据
                // this.otherChangeLoading = false;
                // if (newDataRes.code === '0') {
                //     newPageData = Object.assign({}, this.formatData(newDataRes.data));
                //     newPageData.id = null; // 置空主合同id
                // };
                return false;
            }
            this.$emit('other-change', newPageData.contractCode, newPageData, 'otherChange');
        },
        updateOtherChangeVisible(val) {
            this.otherChangeVisible = val;
        },
        handlePriceChange () {
            this.activeName = 'otherChangeDetail';
            // 滚动到页面底部
            const priceDocu = document.querySelector('.contract-manage .contract-list-tab-pane.el-tabs > .el-tabs__content > .el-tab-pane.detail-pane');
            priceDocu.scrollTop = priceDocu.scrollHeight;
        },
        async changeApply(changeType) {
            let newPageData = Object.assign({}, this.detailData);
            this.otherChangeLoading = true;
            const newDataRes = await getContractInfoByIdApi({ id: this.detailData.id }); // 重新请求主合同数据
            this.otherChangeLoading = false;
            if (+newDataRes.code === 0) {
                newPageData = Object.assign({}, this.formatData(newDataRes.data));
                newPageData.id = null; // 置空主合同id
            };
            if (changeType === 'basic') {
                newPageData.changeType = '0';
            } else if (changeType === 'price') {
                newPageData.changeType = '1';
            } else {
                newPageData.changeType = '2';
            }
            this.$emit('other-change', newPageData.contractCode, newPageData, 'otherChange');
        },
        subTabDbClick(row) {
            this.$emit('subTabDbClick', row);
        },
        async priceClick() { // 价格维护
            let newPageData = {};
            this.priceLoading = true;
            const newDataRes = await getContractInfoByIdApi({ id: this.detailData.id }); // 重新请求主合同数据
            this.priceLoading = false;
            if (newDataRes.code === '0') {
                newPageData = Object.assign({}, this.formatData(newDataRes.data));
            };
            this.$emit('price-click', '6666', newPageData);
        },
        async stopContract() { // 终止协议
            const params = {
                id: this.detailDataLocal.id,
                contractCode: this.detailDataLocal.contractCode,
                contractCharacter: 5
            };
            this.stopContractLoading = true;
            const res = await supplementaryAddApi(params);
            this.stopContractLoading = false;
            if (res.code === '0' && res.data) {
                const newPageData = Object.assign({}, this.detailDataLocal, res.data);
                this.$emit('replenish-contract', newPageData.contractCode, newPageData, 'stop'); // 打开终止协议页面
            }
        },
        refreshAll() {
            const data = JSON.parse(JSON.stringify(this.detailDataLocal));
            this.$emit('refrash-data', data);
        },
        stopLoading() {
            this.detailLoading = false;
        },
        handleClickTab(data) {
            const editTabArr = [ 'filesDetail', 'customerDetail', 'companyDetail',
                'prescreenDetail', 'oilDetail', 'rateDetail' ];
            const readonlyTabArr = ['relationDetail', 'otherChangeDetail', 'iflowDetail'];
            if (editTabArr.includes(data.name)) { // 解决切换编辑表格时操作列错位问题
                this.$nextTick(() => {
                    const tableRef = this.$refs[`${data.name}Ref`].$refs.editTableBox;
                    if (tableRef) {
                        tableRef.$refs.moduleTable.doLayout();
                    }
                });
            }
            if (readonlyTabArr.includes(data.name)) { // 数纯展示表格，刷新数据（只刷新激活状态的）
                this.$refs[`${data.name}Ref`].getDataFn();
            }
        },
        async applyIflow() { // 提交审批
            this.applyIflowFn(this.detailDataLocal.contractCode, 'applyIflowLoading');
        },
        async applyIflowFn(code = this.detailDataLocal.contractCode, loadingKey) { // 提交审批
            if (this.isHandleEdit) {
                return this.$message.warning('数据可能有改动，提交审批前请先保存数据');
            }
            const passFlag = this.$refs.upholdHealder.onsave();
            if (!passFlag) return;
            const { contractName, contractCharacter, mipContractCode } = this.detailData;
            const { origin, pathname } = window.location;
            const isFW = String(contractCharacter) === '4' ? '' : '1';
            // eslint-disable-next-line max-len
            const url = `${origin}${pathname}#/mipiframe?contractCode=${code}&contractName=${contractName}&mipContractCode=${mipContractCode}&contractCharacter=${contractCharacter}&isFW=${isFW}`;
            // const url = `https://crm-sit.annto.com/mdm.html#/mipiframe?contractCode=${this.detailDataLocal.contractCode}`;
            const params = {
                contractCode: code,
                sourceSystemUrl: url
            };
            this[loadingKey] = true;
            const res = await createFlowApi(params);
            this[loadingKey] = false;
            if (res && +res.code === 0) {
                this.$message({
                    message: '提交审批流程成功！稍后可到"流程信息"子页签查看审批流程信息',
                    type: 'success'
                });
                await this.refleshPage();
                this.activeName = 'iflowDetail';
            }
        },
        contractChangeSubmit() { // 变更提交
            if (this.isHandleEdit) {
                return this.$message.warning('数据可能有改动，提交审批前请先保存数据');
            }
            const passFlag = this.$refs.upholdHealder.onsave();
            if (!passFlag) return;
            this.contractChangeSubmitLoading = true;
            contractChangeUpdateStatusApi({ id: this.detailDataLocal.id }).then(async (res) => {
                if (res.code === '0') {
                    if ([1, 2].includes(+this.detailDataLocal.changeType)) { // 价格、日期变更：走iflow 审批
                        this.$message.success('提交审批流程成功！稍后可到"流程信息"子页签查看或审批流程');
                    } else {
                        this.$message.success('提交审批成功');
                    }
                    await this.refleshPage();
                    // 价格信息：从代办进来的需要调用代办完成接口
                    if ([1].includes(+this.detailDataLocal.changeType)) {
                        this.detailData.fdFlowId && priceOtherFinsh({
                            fdFlowId: this.detailData.fdFlowId
                        });
                    };
                };
            }).finally(() => { this.contractChangeSubmitLoading = false; });
        },
        handleContractApproved() {
            const param = {
                id: this.detailDataLocal.id,
                contractCode: this.detailDataLocal.contractCode,
                contractChangeCode: this.detailDataLocal.contractChangeCode,
                contractChangeStatus: this.detailDataLocal.contractChangeStatus
            };
            this.handleContractApproveLoading = true;
            contractChangeUpdateContractApi(param).then(res => {
                if (res.code === '0') {
                    this.$message.success('审批成功');
                    this.refleshPage();
                }
            }).finally(() => { this.handleContractApproveLoading = false; });
        },
        handleContractApprovedReject() {
            const param = {
                id: this.detailDataLocal.id,
                contractCode: this.detailDataLocal.contractCode,
                contractChangeCode: this.detailDataLocal.contractChangeCode,
                contractChangeStatus: this.detailDataLocal.contractChangeStatus
            };
            this.handleContractApprovedRejectLoading = true;
            contractChangeRejectApi(param).then(res => {
                if (res.code === '0') {
                    this.$message.success('驳回成功');
                    this.refleshPage();
                }
            }).finally(() => { this.handleContractApprovedRejectLoading = false; });
        },
        deleteHeader() {
            MessageBox.confirm('确定要删除该合同吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = '';
                this.deleteHeaderLoading = true;
                if (this.detailPageState === STATE_OTHER_CHANGE) {
                    res = await contractChangeDelete({ id: this.detailDataLocal.id });
                } else {
                    res = await contractDelete({ id: this.detailDataLocal.id });
                }
                this.deleteHeaderLoading = false;
                if (res.code === '0') {
                    this.$message.success('删除成功');
                    this.$emit('close-page', this.code + this.detailPageState);
                }
            }).catch(res => {});
        },
        handleClickMoreInfo(data) { // 解决点击折叠/展开“更多信息”折叠筐，子页签编辑错位问题
            if (data.includes('moreInfo')) {
                this.handleClickTab({ name: this.activeName });
            }
        },
        getNewIflowEmit(list) {
            if (list.length) {
                list.forEach(item => {
                    if (+item.mipType !== MAGIC_NUMBER.TWENTY_THREE) { // 排除合同组流程
                        this.fdId = item.mipId || '';
                        this.iflowList = [item];
                    }
                });
            } else {
                this.fdId = '';
                this.iflowList = [];
            }
        },
        // 获取最新iflow流程子页签流程状态数据函数：
        async getNewIflowList(code) {
            return contractWorkflowList({
                contractCode: code,
                pageSize: 10,
                pageNo: 1
            }).then(res => {
                if (+res.code === 0) {
                    if (res.data && res.data.list && res.data.list.length) {
                        // 保存流程状态数据
                        this.fdId = res.data.list[0].mipId;
                        this.iflowList = res.data.list;
                    }
                    return res;
                }
            });
        },
        // eslint-disable-next-line complexity
        async handleViewFlow() {
            const { contractCode, contractName, contractCharacter, contractChangeCode, changeType } = this.detailDataLocal;
            const isFW = (this.detailPageState !== STATE_OTHER_CHANGE && +contractCharacter !== 4) || (this.detailPageState === STATE_OTHER_CHANGE && +changeType === 2);
            const code = this.detailPageState === STATE_OTHER_CHANGE ? contractChangeCode : contractCode;
            if (!this.viewFlowUrl) {
                // 请求流程信息
                this.viewFlowLoading = true;
                const res = await this.getNewIflowList(code);
                this.viewFlowLoading = false;
                if (+res.code === 0 && res.data && res.data.list && res.data.list.length) {
                    if (!isFW
                        // 特殊情况：日期变更（法务流程）时，mip状态为草稿/撤回/审批驳回时, 直接跳转到本系统mipUrl，不跳法务
                        || (this.detailPageState === STATE_OTHER_CHANGE && +changeType === 2 && ['1', '00', '5', '6', '30', '80'].includes(this.iflowList[0].mipStatus + ''))) {
                        this.viewFlowUrl = res.data.list[0].mipUrl;
                    } else {
                        this.viewFlowUrl = res.data.list[0].legalMipUrl;
                    }
                }
            }
            if (this.viewFlowUrl) {
                if (!isFW
                    || (this.detailPageState === STATE_OTHER_CHANGE && +changeType === 2 && ['1', '00', '5', '6', '30', '80'].includes(this.iflowList[0].mipStatus + ''))) {
                    const query = this.detailPageState === STATE_OTHER_CHANGE
                        ? { fdId: this.fdId, fdTemplateKey: changeType === 2 ? '' : 'CRM_contract_price', contractCode: code, changeType }
                        : { fdId: this.fdId, fdTemplateKey: 'LMDM_customer_contract', contractCode: code, contractName, contractCharacter };
                    const routeData = this.$router.resolve({ name: 'mipIframe', query });
                    window.open(routeData.href, '_blank');
                } else {
                    window.open(this.viewFlowUrl, '_blank');
                    // window.open(this.viewFlowUrl, '_self');
                }
            } else {
                this.$message.warning('未查询到流程信息');
            }
        },
        updateChangeStandardVisible(val) {
            this.changeStandardVisible = val;
        },
        handleChangeStandard() {
            this.updateChangeStandardVisible(true);
        },
        handleArchive() {
            this.archiveVisible = true;
        },
        archiveConfirm() {
            this.archiveVisible = false;
            this.refleshPage();
        },
        handlePrintArchive() {
            this.printArchiveVisible = true;
        },
        companyPriceConfirm (val) {
            this.$set(this.detailDataLocal, 'priceMaintainerMip', val.priceMaintainerMip);
            this.$set(this.detailDataLocal, 'priceMaintainerName', val.priceMaintainerName);
            this.$refs.upholdHealder.updataInitData(this.detailDataLocal);
        },

        handleSignature() {
            this.signLoading = true;
            const { contractCode } = this.detailDataLocal;
            pushFileSignature({ contractCode }).then(res => {
                if (res && +res.code === 0) {
                    this.$message.success('推送第三方签章成功');
                }
            }).finally(() => {
                this.signLoading = false;
            });
        },
        handleOtherSignature() {
            this.otherLoading = true;
            const { contractCode } = this.detailDataLocal;
            pushFileOther({
                contractCode
            }).then(res => {
                if (res && +res.code === 0) {
                    this.$message.success('推送智汇签成功');
                }
            }).finally(() => {
                this.otherLoading = false;
            });
        },
        failTimeChange(val, prop) {
            this.detailDataLocal[prop] = val;
            this.$refs.upholdHealder.updataInitData(this.detailDataLocal);
        }
    }
};
</script>

<style lang = "less">
.contract-detail {
    .detail-toolBar {
        .contract-detail-btn {
            margin-right: 10px;
        }
        .item-flow-right {
            float: right;
            margin-left: 10px;
        }
    }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .el-tabs__header {
        margin-bottom: 0px;
    }
    .el-tabs__content {
        .file-detail-btn {
            background-color: #fffbf2;
            .el-button {
                padding: 8px 0px;
            }
        }
        .footer {
            padding: 10px 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .detail-more-collapse.el-collapse {
        border-top: none;
        border-bottom: none;
    }
    .detail-toolBar {
        .el-loading-spinner {
            top: 0;
            margin-top: 0;
            height: 100%;
            .circular {
                height: 26px;
                width: 26px;
            }
        }
    }
    .el-form-item--mini .el-form-item__label[for='operatGrossMarginPlan'] {
        line-height: 16px;
    }
    .el-collapse-item__content {
        padding-bottom: 0;
    }
}
.otherChange-detail {
    /* 其他变更详情页独有样式 */
    .el-col.el-col-6.form-item {
        .el-form-item.el-form-item--mini {
            margin-bottom: 8px;
        }
    }
    .el-col.el-col-24.form-item {
        .el-form-item.el-form-item--mini {
            margin-bottom: 18px;
        }
    }
}
.tips-red {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 1px;
}

.tips-red::after {
    position: absolute;
    width: 100%;
    top: -12px;
    left: 0;
    line-height: 1;
    content: '请填写合同终止日期';
    color: #f56c6c;
    font-size: 12px;
}
</style>
