<template>
    <div class="add-new-main-dialog">
        <el-dialog
            :visible="true"
            title="新增合同预填充"
            width="1000px"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            v-loading="addNewMainLoading"
            @close="emit('handleClose', false)">
            <div class="add-main-form-box">
                <dc-form
                    ref='dcForm'
                    :row="addNewMainData"
                    :config="FIELDS"
                    :col="2"
                    @data-changed="handleFormDataChange"
                    labelWidth="150px">
                </dc-form>
            </div>
            <table-box
                class="add-main-dialog-table"
                v-loading="neoCrmOpportunityList.tableLoading"
                ref="tableBox"
                :selection="true"
                :height="config.height"
                :columns="config.columns"
                :rows="neoCrmOpportunityList.totalData"
                :highlight-current-row="true"
                :selectable="config.selectable"
                @selection-change="handleSelectedChange"
                @row-click="handleRowClick"
            >
            </table-box>
            <lots-pagination
                @size-change="neoCrmOpportunityList.sizeChange"
                :current-page.sync="neoCrmOpportunityList.pageNo"
                @current-change="neoCrmOpportunityList.pageChange"
                layout="total, sizes, prev, pager, next, jumper"
                :total="neoCrmOpportunityList.pageTotal"
                :pageSize="neoCrmOpportunityList.pageSize"
            ></lots-pagination>
            <span slot="footer">
                <el-button type="primary" size="mini" @click="confirm">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api';
import tableBox from '@/components/lots/tableBox/Index';
import lotsPagination from '@/components/lots/lotsPagination/Index';
import { ListClass } from '@/modules/mdm/composition/dc/common/list/ListClass';
import { tableConfig } from './config.js';
import { searchNeoCrmOpportunityApi } from '@mdm/api/contractManage/detailMainApi.js';
import { Message } from 'element-ui';
import dcForm from '@/modules/mdm/components/form/Index.vue';
import store from '@/store';
import utils from '@/components/utils/common.js';
const { MAGIC_NUMBER } = utils;
export default {
    components: { dcForm, tableBox, lotsPagination },
    props: {
        addNewMainLoading: {
            type: Boolean,
            default: false
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const config = reactive(tableConfig);
        const emit = setupContext.emit;
        class DataDetailClass extends ListClass {
            beforeGetList(condition) {
                if (!condition.projectCode) {
                    condition.projectCode = addNewMainData.value['projectCode'];
                    condition.userCode = store.getters.user.userCode;
                }
                return condition;
            };
            afterGetList(res) {
                if (+res.code === 0) {
                    neoCrmOpportunityList.totalData.value = res.data.list.map(item => {
                        item = disabledItemFn(item);
                        return item;
                    });
                    neoCrmOpportunityList.pageTotal.value = res.data.totalCount;
                }
            }
        };
        const disabledItemFn = (item) => {
            if (item.saleStageId !== '中标确认' && item.saleStageId !== '合同签订') {
                item.disabledBid = true;
            } else {
                const { bidDate, contractSearchDate } = item;
                const bidDateTime = bidDate ? new Date(bidDate).getTime() : null;
                const SearchDateTime = contractSearchDate ? new Date(contractSearchDate).getTime() : null;
                const nowTime = new Date().getTime();
                if (!bidDateTime && !SearchDateTime) {
                    item.disabled30 = true;
                }
                if (bidDateTime) {
                    const flag1 = Math.abs(nowTime - bidDateTime) > (60 * 60 * 24 * MAGIC_NUMBER.NINETY * 1000); // 大于当前3个月（90天）时间范围
                    if (!SearchDateTime) {
                        item.disabled30 = flag1;
                    } else {
                        const flag2 = Math.abs(nowTime - SearchDateTime) > (60 * 60 * 24 * MAGIC_NUMBER.NINETY * 1000); // 大于当前3个月（90天）时间范围
                        item.disabled30 = flag1 && flag2;
                    }
                }
            }
            return item;
        };
        const neoCrmOpportunityList = new DataDetailClass({
            Api: {
                listApi: searchNeoCrmOpportunityApi
            }
        });
        const addNewMainData = ref({
            oilIsGroupCustomer: '', // 内外部业务：0 内部 1 外部
            contractCharacter: '',
            mainContract: '',
            projectCode: '',
            projectType: '' // 额外参数
        });
        const FIELDS = computed(() => {
            config.fields.forEach(item => {
                if (item.prop === 'mainContract') {
                    item.visible = addNewMainData.value.contractCharacter === '2';
                }
                if (item.prop === 'projectCode') {
                    item.visible = addNewMainData.value.oilIsGroupCustomer === '1';
                    if (!item.visible) {
                        neoCrmOpportunityList.totalData.value = []; // 清空商机列表
                        mustCheck = false; // 取消商机必选
                    }
                }
            });
            return config.fields;
        });
        const selectedRows = ref([]);
        const handleSelectedChange = (rows) => {
            selectedRows.value = rows;
        };
        const handleRowClick = (row) => {
            if (row.disabledBid) {
                Message.warning('商机未中标，不允许关联');
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row, false);
            } else if (row.disabled30) {
                Message.warning('商机中标日期超出3个月，请与销售管理沟通后进行关联！');
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row, false);
            } else {
                setupContext.refs.tableBox.$refs.elTable.toggleRowSelection(row); // 不传true, 用于切换选中状态
            }
        };
        let mustCheck = false;
        // eslint-disable-next-line complexity
        const handleFormDataChange = ({ data, form, itemSetting }) => {
            // isCheck:是否校验客户主体 projectType:报价项目类型 isLinkOppo:是否校验关联商机
            const projectKeys = ['projectCode', 'projectStatus', 'isCheck', 'projectType', 'isLinkOppo'];
            if (itemSetting.prop === 'projectCode') {
                neoCrmOpportunityList.totalData.value = [];
                if (!data.projectCode) { return false; }
                mustCheck = false; // 初始化商机是否必选
                if (!['6', '8'].includes(form['projectStatus'])) {
                    setTimeout(() => { // 异步解决数据不清空的问题
                        projectKeys.forEach(item => {
                            form[item] = ''; // 清空项目字段信息
                        });
                    });
                    return Message.warning('请关联中标/已签合同项目');
                }
                // if (+form['isCheck'] === 1 && [1, 2].includes(+form['projectType'])) {
                //     setTimeout(() => {
                //         projectKeys.forEach(item => {
                //             form[item] = '';
                //         });
                //     });
                //     return Message.warning('请选择已完成投标报价的项目');
                // }
                if (+form['projectType'] === 1) { // 非标项目
                    form['contractSource'] = '2'; // 合同来源LSRM
                } else if (+form['projectType'] === 2) { // 标准产品项目
                    form['contractSource'] = '3'; // 合同来源 PMS
                } else {
                    form['contractSource'] = '1'; // 合同来源 CRM
                }
                if (+form['isLinkOppo'] === 1 || !+form['isLinkOppo']) {
                    mustCheck = true; // 需要勾选商机
                }
                getOpportunityList(form);
            }
            if (itemSetting.prop === 'contractCharacter') {
                if (['3', '5'].includes(data)) {
                    form['contractCharacter'] = '';
                    return Message.warning('补充协议/终止协议需通过主合同的对应按钮点击生成。');
                }
                if (data !== '2') {
                    form['mainContract'] = '';
                }
            }
            if (itemSetting.prop === 'oilIsGroupCustomer') { // 内外部业务
                if (data === '0' || !data) { // 内部或 空
                    projectKeys.forEach(item => {
                        form[item] = ''; // 清空项目字段信息
                    });
                    form['contractSource'] = '1'; // 合同来源 CRM
                } else {
                    getOpportunityList(form);
                }
            }
            addNewMainData.value = Object.assign(addNewMainData.value, form);
            // 获取商机列表函数
            function getOpportunityList(form) {
                if (form['projectCode']) {
                    neoCrmOpportunityList.getList({
                        pageSize: 50,
                        projectCode: form['projectCode'],
                        userCode: store.getters.user.userCode
                    });
                }
            }
        };
        const confirm = () => {
            if (mustCheck && selectedRows.value.length === 0) {
                return Message.warning('请至少关联一条中标商机');
            }
            const opportunityList = selectedRows.value.map((item) => {
                return { ...item, isPureWarehouse: '' };
            });
            setupContext.refs.dcForm.validateForm().then(res => {
                emit('addNewConfirm', {
                    ...addNewMainData.value,
                    opportunityList
                });
            });
        };
        return {
            emit,
            config,
            neoCrmOpportunityList,
            selectedRows,
            handleSelectedChange,
            handleRowClick,
            confirm,
            handleFormDataChange,
            addNewMainData,
            FIELDS
        };
    }
};
</script>
<style lang="less">
.add-new-main-dialog {
    .add-main-form-box {
        width: 100%;
        padding: 0 40px 0 0;
        box-sizing: border-box;
        .el-select {
            width: 100%;
        }
    }
    .add-main-dialog-table {
        margin: 10px 0 5px 0;
    }
}
</style>
